import * as React from "react";
import { Modal } from "reactstrap";

/**
 * Custom properties for the FadeModal component
 */
export interface FadeModalProperties {
    /**
     * The children to render inside the body of the modal
     */
    children: string | any;

    /**
     * The title in the header of the modal
     */
    headerTitle: string;

    /**
     * Unique id of this modal
     */
    id: string;
}

/**
 * A simple side modal that will fade the background.
 * 
 * @param props The FadeModalProperties of this modal
 * @returns FadeModal component
 */
const FadeModal: React.FC<FadeModalProperties> = (props: FadeModalProperties) => {
    const {headerTitle, id } = props;

    return  <div className="modal fade" id={props.id} aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5 fw-bold">{props.headerTitle}</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          {props.children}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>;
};

export default FadeModal;