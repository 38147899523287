import * as React from 'react';
import * as Utils from '../../Utils';

import { useNavigate } from 'react-router';
import CreateGame from '../../Commands/CreateGame';
import UpdateGame from '../../Commands/UpdateGame';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import { Card, CardBody, CardFooter, CardHeader, CardTitle } from 'reactstrap';
import TextInput from '../common/TextInput';
import ImageInput from '../common/ImageInput';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import Game from '../../Models/Game';
import { CurrentUserContext } from '../../contexts/AuthContext';
import FadeModal from '../common/FadeModal';
import getMutedDurationString from '../../Utils/MutedTillDateFormat';

export interface GameFormProps {
    game?: Game;
};

interface GameFormState {
    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The CreateGame command our form is building
     */
    formData: CreateGame | UpdateGame;

    /**
     * The game categories available
     */
    gameGenres: string[];

    /**
     * The game Population
     */
    gamePopulations: string[];

    /**
     * The statustype of this game
     */
    gameStatusTypes: string[];
}

const GameForm: React.FC<GameFormProps> = (props: GameFormProps) => {
    const api = useApi();
    const navigate = useNavigate();
    const isUpdate = props.game && props.game.id > 0;
    const { userAuth } = React.useContext(CurrentUserContext);
    const [showModal, setShowModal] = React.useState(userAuth?.userAccount?.isMuted == true? true: false);

    const incomingFormData = {
        description: props.game?.description ?? '',
        id: props.game?.id,
        name: props.game?.name ?? '',
        websiteUrl: props.game?.websiteUrl ?? '',
        population: props.game?.population,
        status: props.game?.status,
        genre: props.game?.genre
    }

    /**
     * Initialize our state object
     */
    const [state, setState] = React.useState({
        err: '',
        fieldErrors: {},
        formData: incomingFormData as UpdateGame,
        gameGenres: [],
        gamePopulations: [],
        gameStatusTypes: []
    } as GameFormState);

    React.useEffect(() => {
        (async () => {
            var gameGenres = await api.get('games/genres');
            var gamePopulations = await api.get('games/populations');
            var gameStatusTypes = await api.get('/games/statustypes');

            setState({
                ...state,
                gameGenres,
                gamePopulations,
                gameStatusTypes
            });
        })();
    }, [api, state]);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            const newGame = isUpdate ?
                await api.put(`/games/${props.game?.id}`, formData) :
                await api.post('/games', formData);

            navigate(`/games/${newGame.id}`);
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }

    }, [api, state]);

    return <Card>
        <form onSubmit={handleSubmit}>
            <CardHeader>
                <CardTitle>
                    {props.game && <h1>Update {props.game.name}</h1>}
                    {!props.game && <h1>Create Game</h1>}
                </CardTitle>
            </CardHeader>

            <CardBody>
                <div className="mb-3">
                    {props.game && <p>Use this form to update {props.game.name}</p>}
                    {!props.game && <p>Use this form to create the game</p>}
                </div>

                <TextInput
                    error={state.fieldErrors['Name']}
                    label="Game name *"
                    name="name"
                    onChange={handleFormChange}
                    tabIndex={1}
                    tooltip="The name of this game"
                    value={state.formData.name}
                />

                <ImageInput
                    error={state.fieldErrors['CoverImage']}
                    label="Cover Image"
                    name="coverImage"
                    onChange={handleFormChange}
                    tabIndex={2}
                    tooltip="This is the image we will use throughout the site for this game"
                    value={state.formData.coverImage}
                />

                <SelectInput
                    error={state.fieldErrors['Genre']}
                    label="Game Genre"
                    name="genre"
                    noneLabel="(Unknown)"
                    onChange={handleFormChange}
                    options={state.gameGenres.map(v => ({ value: v } as SelectInputOption))}
                    tabIndex={3}
                    tooltip="Optionally you can select a genre for this game"
                    value={state.formData.genre}
                />

                <SelectInput
                    error={state.fieldErrors['Population']}
                    label="Game Population"
                    name="population"
                    noneLabel="(Unknown)"
                    onChange={handleFormChange}
                    options={state.gamePopulations.map(v => ({ value: v } as SelectInputOption))}
                    tabIndex={4}
                    tooltip="you can select a population for this game"
                    value={state.formData.population}
                />

                <SelectInput
                    error={state.fieldErrors['StatusType']}
                    label="Game Status"
                    name="status"
                    noneLabel="(Unknown)"
                    onChange={handleFormChange}
                    options={state.gameStatusTypes.map(v => ({ value: v } as SelectInputOption))}
                    tabIndex={5}
                    tooltip="Optionally you can select a status for this game"
                    value={state.formData.status}
                />

                <TextInput
                    error={state.fieldErrors['Description']}
                    isLongText={true}
                    label="Game description *"
                    name="description"
                    onChange={handleFormChange}
                    tabIndex={6}
                    tooltip="This is where you place your actual game description"
                    value={state.formData.description}
                />

                <TextInput
                    error={state.fieldErrors['WebsiteUrl']}
                    label="Game Link *"
                    name="websiteUrl"
                    onChange={handleFormChange}
                    tabIndex={7}
                    tooltip="The url to game website"
                    value={state.formData.websiteUrl}
                />

                <div className="text-end text-danger">
                    &nbsp;
                    {state.err as string}
                </div>
            </CardBody>

            <CardFooter className="text-end">
                {
                    showModal ? <button className="btn btn-primary" tabIndex={7} data-bs-toggle="modal" data-bs-target="#MutedModal">{props.game && <h5>Update {props.game.name}</h5>}
                    {!props.game && <h5>Create Game</h5>}</button> : <button className="btn btn-primary" tabIndex={7}>
                    {props.game && <h5>Update {props.game.name}</h5>}
                    {!props.game && <h5>Create Game</h5>}
                </button>
                }
                <FadeModal id="MutedModal" headerTitle="You Are Muted"><span className='fw-bold'>You have been muted for {userAuth?.userAccount?.mutedTillDate && getMutedDurationString(userAuth?.userAccount?.mutedTillDate)} For the following reason:</span> <div>{userAuth?.userAccount?.muteReason}</div></FadeModal>
            </CardFooter>
        </form>
    </Card>;
};

export default GameForm;
