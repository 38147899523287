import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Game from '../../Models/Game';
import { ApiException } from '../../Api';
import useApi from '../../Utils/useApi';
import { useParams } from 'react-router';
import ForumCard from '../forums/ForumCard';
import Forum from '../../Models/Forum';
import { CurrentUserContext } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import GameCommentSection from './GameCommentSection';
import GameHeader from './GameHeader';
import GameCommentCard from './GameCommentCard';

interface GameDetailPageState {
    errors?: ApiException | Error | string;
    game: Game;
    isLoading: boolean;
    relatedForums: Forum[];
    totalComments: number;
}

const GameDetailPage: React.FC = () => {
    const [state, setState] = React.useState({
        game: {} as Game,
        errors: '',
        isLoading: true,
        relatedForums: [] as Forum[],
        totalComments: 0
    } as GameDetailPageState);

    const api = useApi();
    const { id } = useParams();
    const { userAuth } = React.useContext(CurrentUserContext);

    const forumQuery = {
        gameId: id,
        sortBy: 'CreatedOn',
        take: 4
    };

    React.useEffect(() => {
        getGame();
    }, [api, id]);

    const getGame = React.useCallback(async () => {
        try {
            const game = await api.get(`games/${id}`);
            const relatedForums = await api.get(`forums`, forumQuery);
            const totalComments = await api.get(`games/${id}/comments`);
            setState({
                ...state,
                game,
                isLoading: false,
                relatedForums,
                totalComments
            });
        } catch (err: ApiException | Error | string | any) {
            setState({
                ...state,
                errors: err
            })
            console.log(err);
        }
    }, [api, id]);

    const { game, isLoading } = state;

    return <div>
        {isLoading && <div>Loading...</div>}

        {game && <div className="detailPage">
                <div className='navContainer d-flex justify-content-center lightblack'>
                    <GameHeader game={game} totalComments={state.totalComments} getGame={getGame} className='navContain'/>
                </div>

            <div className='navContainer d-flex justify-content-center'>
                <div className="content post-content navContain">
                {game.description}
                </div>
            </div>
            <div className='px-3'>
                <div>
                    <h2 className="mb-3 mt-4 text-primary">Comments</h2>
                </div>
                <div>
                    <h4>Conversation <small className="text-muted">{state.totalComments} comments</small></h4>
                </div>
                <div>
                    {!userAuth?.userAccount && <div className='d-flex'>
                        <div>
                            <Link to="/users/signin" className="nav-link pe-2">Login</Link>
                        </div>|
                        <div>
                            <Link to="/users/signup" className="nav-link ps-2">SignUp</Link>
                        </div>
                    </div>}
                </div>
                <div>
                    {userAuth?.userAccount && <GameCommentSection buttonname='Comment' />}

                    {game.comments?.map((comment) => <GameCommentCard depth={0} replyTo={game.createdBy?.username ?? game.createdBy?.emailAddress} key={comment.id} comment={comment} />)}
                </div>
                <div>
                    {(state.relatedForums && state.relatedForums.length > 0) && <div>
                        {state.relatedForums.length > 0 ?
                            <h3 className='text-primary mt-5'>Related Forums</h3> : ''}

                        {state.relatedForums.map((forum) => (
                            <ForumCard key={forum.id} forum={forum} />
                        ))}
                    </div>}
                </div>
            </div>
        </div>}
    </div>
}
export default GameDetailPage;