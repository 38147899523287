import * as React from 'react';
import { useParams } from 'react-router';
import { Col, Container } from 'reactstrap';
import { ApiException } from '../../Api';
import UserAccount from '../../Models/UserAccount';
import useApi from '../../Utils/useApi';
import ProfileCard from './ProfileCard';
import { ListGames } from '../../Commands/ListGames';
import GameCard from '../Game/GameCard';
import Game from '../../Models/Game';
import ForumCard from '../forums/ForumCard';
import { ListForums } from '../../Commands/ListForums';
import Forum from '../../Models/Forum';

/**
 * Internally used profile page state
 */
interface ProfilePageState {
    errorMessage?: string;
    user?: UserAccount;
    gameformData?: ListGames;
    games?: Game[];
    forums?: Forum[];
    forumformData?: ListForums;
}

const ProfilePage: React.FC = () => {
    const api = useApi();
    const { userId } = useParams();
    const [state, setState] = React.useState({
        games: [] as Game[],
        forums: [] as Forum[],

        user: {
            avatarImage: '',
            bio: '',
            emailAddress: '',
            facebookUsername: '',
            firstName: '',
            lastName: '',
            redditUsername: '',
            siteRole: '',
            twitterHandle: '',
            username: ''
        }as UserAccount,

        errorMessage: '',

        gameformData: {
        starRating: 0,
        sortAscending: false,
        sortBy: 'CreatedOn',
        search: '',
        userId: userId?? 0
        }as ListGames,

        forumformData: {
            category: '',
            sortAscending: false,
            sortBy: '',
            search: '',
            userId: userId?? 0
            }as ListForums},
        );

    React.useEffect(() => {
        const getUser = async () => {
            try {
                const user = await api.get(`users/${userId}`);
                const games = await api.get('games', state.gameformData);
                const forums = await api.get('forums', state.forumformData);
                setState({
                    ...state,
                    user,
                    games: games,
                    forums: forums
                });
            } catch (err: ApiException | any) {
                if (err.statusCode === 404) {
                    setState({
                        ...state,
                        errorMessage: 'User not found'
                    });
                }
                console.log(err);
            }
        };

        getUser();
    }, [userId])

    return <Container>
        {state?.errorMessage && <h1 className="text-danger">{state.errorMessage}</h1>}

        {state?.user && <>
            <ProfileCard user={state.user} />

            {state.user.bio && <div className="p-4">
                <h1>User Bio:</h1>
                <div className="post-content">
                    {state.user.bio}
                </div>
            </div>}

            <div className="mt-5">
                <h1>Recent Activity:</h1>
                
                <h3 className='mt-5'>Games:</h3>
                {state.games?.map(game => <Col xs={12}>
                    <GameCard game={game}/>
                </Col>)}

                <h3 className='mt-5'>Forums:</h3>
                {state.forums?.map(forum => <Col xs={12}>
                    <ForumCard forum={forum}/>
                </Col>)}
            </div>
        </>}
    </Container>;
};

export default ProfilePage;
