import * as React from 'react';
import * as Utils from '../../Utils';

import { Button, Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { ListForums } from '../../Commands/ListForums';
import useApi from '../../Utils/useApi';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import Forum from '../../Models/Forum';

import { Link, useNavigate } from 'react-router-dom';
import ForumCard from './ForumCard';
import SortBar from '../common/SortBar';
import { CurrentUserContext } from '../../contexts/AuthContext';
import FadeModal from '../common/FadeModal';
import getMutedDurationString from '../../Utils/MutedTillDateFormat';

interface ForumIndexState {
    /**
     * The general error message to show
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The forum categories available
     */
    forumCategories: SelectInputOption[];

    /**
     * The list of forums that we are showing
     */
    forums?: Forum[];

    isLoading?: boolean;

    /**
     * The CreateUserAccount command our form is building
     */
    formData: ListForums;
}

const ForumIndexPage: React.FC = () => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const api = useApi();
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(userAuth?.userAccount?.isMuted == true? true: false);


    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            category: '',
            sortAscending: false,
            sortBy: '',
            search: '',
        }as ListForums,
        isLoading: true,
        forumCategories: []
    } as ForumIndexState);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
        getForums();
    }, [state]);

    const setSortBy = React.useCallback((value: string) => {
        Utils.handleFormChange(state, setState, 'sortBy', value);
        getForums();
    }, [state]);

    const getForums = React.useCallback(() => {
        (async () => {
            console.log(state.formData);
            var forums = await api.get('forums', state.formData);
            setState({ ...state, forums });
        })();
    }, [state]);

    React.useEffect(() => {
        (async () => {
            const res = await api.get('forums/categories');
            const forumCategories = res.map((v: string) => ({ value: v } as SelectInputOption));
            const forums = await api.get('forums', state.formData);
            console.log(state.formData);
            setState({ ...state, forums, forumCategories, isLoading: false });
        })();
    }, []);

    var addForumClick = React.useCallback(async (e: any) => {
        if (!userAuth?.userAccount) {
            const message = encodeURIComponent('Please sign in to create forum thread');
            const redirectUrl = encodeURIComponent(`/forums/create`);

            navigate(`/users/signin?message=${message}&redirectUrl=${redirectUrl}`);
        } else {
            if(!userAuth?.userAccount?.isMuted){
                navigate('/forums/create');  
            }
        }
    }, [userAuth])

    return <>
        {state.isLoading && <strong>Loading...</strong>}
            <div className="p-3 index forumindex">
                {/* Filter bar start*/}
                <div className='filter-bar'>
                    {
                    showModal ? <Button className='btn btn-warning mb-4 btnfont' onClick={addForumClick} data-bs-toggle="modal" data-bs-target="#MutedModal"><h5>Start a Forum</h5></Button> : <Button className='btn btn-warning mb-4 btnfont' onClick={addForumClick}>
                    <h5>Start a Forum</h5>
                    </Button>
                    }
                    <FadeModal id="MutedModal" headerTitle="You Are Muted"><span className='fw-bold'>You have been muted for {userAuth?.userAccount?.mutedTillDate && getMutedDurationString(userAuth?.userAccount?.mutedTillDate)} For the following reason:</span> <div>{userAuth?.userAccount?.muteReason}</div></FadeModal>

                    <Card className='mb-4'>
                        <ul className="list-group list-group-flush">
                            <CardHeader className="list-group-item mb-3">
                                <i className="me-3 fas fa-comments"></i><span className='tagmainfont'> All forums</span>
                            </CardHeader>
                            <div>
                                <SelectInput
                                    error={undefined}
                                    label="By Category"
                                    name="category"
                                    noneLabel="(ALL)"
                                    onChange={handleFormChange}
                                    options={state.forumCategories}
                                    tabIndex={2}
                                    tooltip="Optionally you can select a category for this forum thread"
                                    value={state.formData.category}
                                />
                            </div>
                        </ul>
                    </Card>
                </div>
                {/* Filter bar end*/}

                {/* Main display start */}
                <div className='main-display'>
                        {/* Sort by start */}
                        <div className="pb-1">
                            <div className="d-flex p-0
                            align-items-center justify-content-between mb-2">
                                <form action="">
                                    <div className="input-group rounded">
                                        <input
                                            type="search"
                                            className="form-control rounded"
                                            placeholder="Search by name"
                                            onChange={(e) => handleFormChange('search', e.target.value)}
                                            value={state.formData.search}
                                            name="search"
                                        />
                                    </div>
                                </form>
                                <SortBar sortParameters={["CreatedOn", "ModifiedOn", "Upvotes"]} onChange={setSortBy} />
                            </div>
                        </div>
                        {/* Sort by end */}

                        {state.forums?.map(forum => (
                            <ForumCard key={forum.id} onCategorySelected={handleFormChange} forum={forum} />
                        ))}

                </div>
                {/* Main display end */}
            </div>
    </>
};

export default ForumIndexPage;
