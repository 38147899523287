import * as React from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import Forum from '../../Models/Forum';
import Game from '../../Models/Game';
import useApi from '../../Utils/useApi';
import ForumCard from '../forums/ForumCard';
import GameCard from '../Game/GameCard';

interface HomePageState {
    recentForums?: Forum[];
    recentGames?: Game[];
};

const relatedQuery = {
    sortBy: 'CreatedOn',
    take: 4
}

const HomePage: React.FC = () => {
    const [state, setState] = React.useState({} as HomePageState);
    const api = useApi();

    React.useEffect(() => {
        (async () => {
            const recentForums = await api.get(`/forums`, relatedQuery);
            const recentGames = await api.get(`/games`, relatedQuery);

            setState({ recentForums, recentGames });
        })();
    }, []);

    return <div id="HomePage">
        <div className="BannerContainer">
            <div className="BannerContent">
                <pre>
                    &nbsp;                                                               dddddddd<br />
                    &nbsp;MMMMMMMM               MMMMMMMM                                d::::::d                                                              iiii          tttt<br />
                    &nbsp;M:::::::M             M:::::::M                                d::::::d                                                             i::::i        ttt:::t<br />
                    &nbsp;M::::::::M           M::::::::M                                d::::::d                                                              iiii        t:::::t<br />
                    &nbsp;M:::::::::M         M:::::::::M                                d::::::d                                                                          t:::::t<br />
                    &nbsp;M::::::::::M       M::::::::::M uuuuuu      uuuuuu      dddddddd::::::d    mmmmmmm    mmmmmmm    uuuuuu    uuuuuu nnnn  nnnnnnnn    iiiiii ttttttt:::::ttttttt yyyyyyy           yyyyyyy<br />
                    &nbsp;M:::::::::::M     M:::::::::::M u::::u      u::::u    dd::::::::::::::d  mm:::::::m  m:::::::mm  u::::u    u::::u n:::nn::::::::nn  i::::i t:::::::::::::::::t  y:::::y         y:::::y<br />
                    &nbsp;M:::::::M::::M   M::::M:::::::M u::::u      u::::u   d::::::::::::::::d m::::::::::mm::::::::::m u::::u    u::::u n::::::::::::::nn i::::i t:::::::::::::::::t   y:::::y       y:::::y<br />
                    &nbsp;M::::::M M::::M M::::M M::::::M u::::u      u::::u  d:::::::ddddd:::::d m::::::::::::::::::::::m u::::u    u::::u n:::::::::::::::n i::::i tttttt:::::::tttttt    y:::::y     y:::::y<br />
                    &nbsp;M::::::M  M::::M::::M  M::::::M u::::u      u::::u  d::::::d    d:::::d m:::::mmm::::::mmm:::::m u::::u    u::::u n:::::nnnn::::::n i::::i       t:::::t           y:::::y   y:::::y<br />
                    &nbsp;M::::::M   M:::::::M   M::::::M u::::u      u::::u  d:::::d     d:::::d m::::m   m::::m   m::::m u::::u    u::::u n::::n    n:::::n i::::i       t:::::t            y:::::y y:::::y<br />
                    &nbsp;M::::::M    M:::::M    M::::::M u::::u      u::::u  d:::::d     d:::::d m::::m   m::::m   m::::m u::::u    u::::u n::::n    n:::::n i::::i       t:::::t             y:::::y:::::y<br />
                    &nbsp;M::::::M     MMMMM     M::::::M u:::::uuuuuu:::::u  d:::::d     d:::::d m::::m   m::::m   m::::m u:::::uuuu:::::u n::::n    n:::::n i::::i       t:::::t    tttttt    y:::::::::y<br />
                    &nbsp;M::::::M               M::::::M u:::::::::::::::uu  d::::::ddddd::::::d m::::m   m::::m   m::::m u:::::::::::::uu n::::n    n:::::n i::::i       t::::::tttt:::::t     y:::::::y<br />
                    &nbsp;M::::::M               M::::::M  u:::::::::::::::u  d:::::::::::::::::d m::::m   m::::m   m::::m  u:::::::::::::u n::::n    n:::::n i::::i        tt::::::::::::::t     y:::::y<br />
                    &nbsp;M::::::M               M::::::M   uu::::::::uu:::u  d:::::::::ddd::::d  m::::m   m::::m   m::::m   uu::::::::uuu  n::::n    n:::::n i::::i         tt:::::::::::tt     y:::::y<br />
                    &nbsp;MMMMMMMM               MMMMMMMM     uuuuuuuuuuuu      dddddddddddddd    mmmmmm   mmmmmm   mmmmmm    uuuuuuuuuuuu  nnnnnn    nnnnnn  iiiiii           ttttttttttt      y:::::y<br />
                    &nbsp;                                                                                                                                                                     y:::::y<br />
                    &nbsp;                                                                                                                                                                    y:::::y<br />
                    &nbsp;                                                                                                                                                                   y:::::y<br />
                    &nbsp;                                                                                                                                                                  y:::::y<br />
                    &nbsp;                                                                                                                                                                 yyyyyyy
                </pre>
            </div>
        </div>

        <Container className="pt-3">
            <h1>Welcome to Mudmunity! Unleash Your Imagination!</h1>
            <p>
                Have you ever yearned for a realm where your imagination knows no bounds? A place where you can embark on epic adventures,
                weave intricate tales, and forge everlasting friendships, all through the power of words? Look no further, for you have found
                your haven in the enchanting realm of MUD Games!
            </p>

            <p>
                MUD, an acronym for Multi-User Dungeon, is a captivating genre of text-based online games that has captivated players for decades.
                Within these virtual realms, you are transported to intricate worlds crafted entirely through words, where your actions, decisions,
                and creativity shape the fabric of the game.
            </p>

            <p>
                In MUD Games, the possibilities are endless. You can step into the shoes of valiant warriors, cunning thieves, mighty sorcerers,
                or even otherworldly beings. Engage in perilous quests, engage in strategic battles, uncover hidden treasures, and unravel complex
                mysteries. Whether you prefer medieval fantasies, futuristic sci-fi settings, or any other genre that tickles your fancy, there's
                a MUD Game out there for you.
            </p>

            <p>
                But where can you find these captivating worlds? Look no further than our vibrant MUD Games community! We have created a virtual
                home for developers and players alike, a sanctuary where you can explore, discover, and connect with a vast array of MUD Games and
                their passionate communities.
            </p>

            <p>
                Discover the magic that awaits you at Mudmunity.com. Welcome Home!
            </p>

            <div className="pt-3">
                <Row>
                    <Col xs={12} md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    For Developers
                                </CardTitle>
                            </CardHeader>

                            <CardBody>
                                Developers, this is your stage! Showcase your creations, share details about your games, and entice players with thrilling tales of
                                your virtual realms. Our community provides the perfect platform to introduce your MUD Game to an eager audience, fostering a strong
                                player base that will breathe life into your virtual world.
                            </CardBody>
                        </Card>
                    </Col>

                    <Col xs={12} md={6}>
                        <Card>
                            <CardHeader>
                                <CardTitle>
                                    For Players
                                </CardTitle>
                            </CardHeader>

                            <CardBody>
                                Players, embark on a grand journey of discovery! Immerse yourself in a treasure trove of MUD Games, where every adventure is a gateway
                                to endless excitement. Explore the game descriptions, seek out the hidden gems, and join the vibrant forums to interact with fellow
                                players and developers. Engage in lively discussions, exchange tips and strategies, or even form alliances to conquer the most
                                challenging quests together.
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>

            <div className="pt-3">
                <h1>MUD Games:</h1>
                <p>
                    So, fellow adventurers, join us in this extraordinary odyssey through the realms of MUD Games! Unleash your imagination, dive into
                    captivating narratives, and forge lifelong friendships in the ever-expanding world of text-based online gaming.
                </p>

                <div className="pt-3">
                    {(state.recentGames && state.recentGames.length > 0) && <Row>
                        {state.recentGames.map((game) => 
                            <GameCard game={game} />
                        )}
                    </Row>}
                </div>
            </div>

            <div className="pt-3">
                <h1>Active Forums:</h1>
                <p>
                    Our community forums serve as a hub of knowledge, a place where you can seek guidance, share your experiences, and form lasting bonds
                    with like-minded adventurers. From game-specific discussions to global conversations about the MUD gaming world, our forums are a treasure
                    trove of information and camaraderie.
                </p>

                {(state.recentForums && state.recentForums.length > 0) && <div className="pt-3">
                    {state.recentForums.map((forum) => <ForumCard key={forum.id} forum={forum} />)}
                </div>}
            </div>
        </Container>
    </div>;
}

export default HomePage;
