import * as React from 'react';

/**
 * Custom properties for our ImageInput control
 */
interface ImageInputProps {
    /**
     * Error message to display for this text box
     */
    error?: string;

    /**
     * Label to display for this text box
     */
    label: string;

    /**
     * Name of the value this image controls (used when input calls onChange)
     */
    name: string;

    /**
     * The callback to invoke when the user selects a different file
     * 
     * @param name Will be the name from props
     * @param value Will be the base64 encoded image selected by the user
     */
    onChange: (name: string, value?: string) => void;

    /**
     * Optional html tab index of this text box
     */
    tabIndex?: number;

    /**
     * Optional tooltip to display when the user hovers the (?)
     */
    tooltip?: string;

    /**
     * Current value to show in the textbox
     */
    value?: string;

    /**
     * Disable the textbox?
     */
    disabled?: boolean;
}

/**
 * Renders an image input
 * 
 * @param props TextInputProps
 * @returns TextInput
 */
const ImageInput: React.FC<ImageInputProps> = (props: ImageInputProps) => {
    /**
     * Calls the props.onChange when the user changes the value of the textbox,
     * parent should change the props.value to update the screen
     */
    const handleChange = React.useCallback((e: any) => {
        const file = e.target.files[0] as File;

        // This reader is how we load the file the user selected
        const reader = new FileReader();
        reader.onload = () => {
            const value = reader.result as string;
            props.onChange(props.name, value);
        };

        // Read the file and call reader.onload when done
        reader.readAsDataURL(file);

        const val = (e.target as any).value;
        props.onChange(props.name, val);
    }, [props]);

    return <div className="mb-3">
        <div>
            {props.tooltip &&
                <i className="fa fa-question-circle me-2" title={props.tooltip} />
            }

            <label>{props.label ?? props.name}</label>

            <input
                type="file"
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                className="form-control"
                name={props.name}
                onChange={handleChange}
                tabIndex={props.tabIndex}
            />

            <span className="text-danger">
                &nbsp;{props.error}
            </span>
        </div>
    </div>;
};

export default ImageInput;
