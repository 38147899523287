import React from 'react';
import { Route, Router, Routes } from 'react-router';
import './App.css';
import TopNav from './components/common/TopNav';
import CreateForumPage from './components/forums/CreateForumPage';
import ForumIndexPage from './components/forums/ForumIndexPage';
import HomePage from './components/home/HomePage';
import CreateUserPage from './components/users/CreateUserPage';
import ProfilePage from './components/users/ProfilePage';
import SignInPage from './components/users/SignInPage';
import SignOutPage from './components/users/SignOutPage';
import UpdateProfilePage from './components/users/UpdateProfilePage';
import { AuthProvider } from './contexts/AuthContext';
import CreateGamePage from './components/games/CreateGamePage';
import GameDetailPage from './components/Game/GameDetailPage';
import GameIndexPage from './components/Game/GameIndexPage';
import UpdateGamePage from './components/games/UpdateGamePage';
import ForumDetailPage from './components/forums/ForumDetailPage';
import VerifyEmailAddressPage from './components/users/VerifyEmailAddressPage';
import ResetPasswordPage from './components/users/ResetPasswordPage';
import ForgotPasswordPage from './components/users/ForgotPasswordPage';
import NotFound from './components/common/NotFound';
import SubmitFeedbackPage from './components/users/SubmitFeedbackPage';
import ReportBugPage from './components/users/ReportBug';
import PendingFeedBack from './components/users/PendingFeedBack';
import UserModerationPage from './components/users/UserModerationPage';
import MuteUserForm from './components/users/MuteUserForm';

function App() {    
    return <AuthProvider>
        <div id="AppContainer">
            <header id="AppHeader">
                <TopNav />
            </header>

            <div id="PageContainer" className="pb-5">
                <Routes>
                    <Route path="/" element={<HomePage />} />

                    <Route path="/forums/create/:gameId" element={<CreateForumPage />} />
                    <Route path="/forums/create" element={<CreateForumPage />} />
                    <Route path="/forums/:forumId" element={<ForumDetailPage />} />
                    <Route path="/forums/:forumId/edit" element={<CreateForumPage />} />
                    <Route path="/forums" element={<ForumIndexPage />} />

                    <Route path="/games/create" element={<CreateGamePage />}></Route>
                    <Route path='/games/:id' element={<GameDetailPage />}></Route>
                    <Route path='/games/:id/update' element={<UpdateGamePage />}></Route>
                    <Route path='/games' element={<GameIndexPage />}></Route>

                    <Route path="/users/:userId/profile" element={<ProfilePage />} />
                    <Route path="/users/:userId/update" element={<UpdateProfilePage />} />
                    <Route path="/users/forgotPassword" element={<ForgotPasswordPage />} />
                    <Route path="/users/resetPassword" element={<ResetPasswordPage />} />
                    <Route path="/users/verifyEmail" element={<VerifyEmailAddressPage />} />

                    <Route path="/users/bug" element={<ReportBugPage />} />
                    <Route path="/users/feedback" element={<SubmitFeedbackPage />} />
                    <Route path="/users/signin" element={<SignInPage />} />
                    <Route path="/users/signout" element={<SignOutPage />} />
                    <Route path="/users/signup" element={<CreateUserPage />} />

                    <Route path="*" element={<NotFound />} />

                    <Route path="/users/pendingfeedback" element={<PendingFeedBack />} />
                    <Route path="/users/moderation" element={<UserModerationPage />} />

                    <Route path="/users/moderation" element={<UserModerationPage />} />
                    <Route path="/users/:userId/muteform" element={<MuteUserForm />} />

                </Routes>
            </div>
        </div>
    </AuthProvider>;
}

export default App;
