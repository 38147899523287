import * as React from 'react';
import { Col, Container, Row, Card, CardTitle, CardBody, CardFooter, CardText, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import Forum from '../../Models/Forum';
import useApi from '../../Utils/useApi';
import ThumbnailImage from '../common/ThumbnailImage';
import { ApiException } from '../../Api';

export interface ForumCardProps {
    forum: Forum
    onCategorySelected?: (name:string, value?: string) => void;
};

interface ForumCardState {
    totalComments: number;
    gameName: string;
}

const ForumCard: React.FC<ForumCardProps> = (props: ForumCardProps) => {
    const api = useApi();
    const { forum } = props;

    const handleCategorySelected = React.useCallback((name: string, value: string) => {
        if (props.onCategorySelected){
          props.onCategorySelected(name , value);
        }
      }, [props]);

    const [state, setState] = React.useState({totalComments: 0, gameName:''}as ForumCardState);

    const forumDetailUrl = `www.mudmunity.com/forums/${forum.id}`;
    const encodedForumDetailUrl = encodeURIComponent(forumDetailUrl);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(forumDetailUrl);
      };

    function trimString(text:string) {
        const maxLength = 500;
        const maxNewLines = 3;
        const ellipsis = "...";
      
        let trimmedText = text.slice(0, maxLength);
      
        let thirdNewLineIndex = -1;
        let newLineCount = 0;
        for (let i = 0; i < trimmedText.length; i++) {
          if (trimmedText[i] === '\n') {
            newLineCount++;
            if (newLineCount === maxNewLines) {
              thirdNewLineIndex = i;
              break;
            }
          }
        }
      
        if (thirdNewLineIndex !== -1) {
          trimmedText = trimmedText.slice(0, thirdNewLineIndex);
        }
      
        if (trimmedText.length < text.length) {
          trimmedText += ellipsis;
        }
      
        return trimmedText;
      }  
          
      
    React.useEffect(() => {
        (async () => {
            if(forum.gameId){
            const gameName = await api.get(`forums/${forum.id}/gamename`);
            setState({
                ...state,
                gameName: gameName
            });}
        })();
    }, []);



    React.useEffect(() => {
            getComment();
        }, [api, forum.id]);


    const getComment = React.useCallback(async () => {
        try {
            const totalComments = await api.get(`forums/${forum.id}/comments`);
            setState({
                ...state,
                totalComments: totalComments
            });
        } catch (err: ApiException | Error | string | any) {
            console.log(err);
        }
    }, [api, forum.id]);


    return <div>
        <Card className='my-3 indexCard'>
            <CardBody>
                <div className="text-lead lh-1 align-self-start py-0 indexCardBody">
                    <ThumbnailImage forumId={forum.id} />
                    <div className='titletext px-3'>
                        <CardTitle className='me-1 text-primary'>
                            <Row>
                                <Col className='col-auto'>
                                <Link className='text-decoration-none text-primary' to={`/forums/${forum.id}`}>
                                    <h1 className='mt-2'>{forum.title}</h1></Link>
                                </Col>
                                <Col className='col-auto'>
                                    {forum?.category && (<button className='btn btn-outline-primary py-1 px-2 mb-1' onClick={() => props.onCategorySelected?.('category', forum.category)}>{forum.category}</button>)
                                    }
                                </Col>
                                <Col className='col-auto'>
                                    {state.gameName && 
                                        <Link className='text-decoration-none text-danger btn btn-outline-danger py-1 px-2 mb-1' to={`/games/${forum.gameId}`}>{state.gameName}</Link>}
                                </Col>
                            </Row>
                            <p className="small text-muted">
                                <>
                                    Started by &nbsp;
                                    <Link to={`/users/${forum.createdById}/profile`} className='brightgreen greenonhover text-decoration-none'>{forum.createdBy ? forum.createdBy.username ?? forum.createdBy.emailAddress : 'System'}</Link>
                                    - {new Date(forum.createdOn).toDateString()}
                                </>
                            </p>
                        </CardTitle>

                        <CardText className='m-0 p-0 post-content'>
                            {trimString(forum.post)}
                        </CardText>
                    </div>
                </div>    
            </CardBody>
            <CardFooter>
                <Row className='mb-2 pt-2'>
                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-chevron-up fa-lg me-1 ms-3"></i>{forum.upvotes}
                    </Col>

                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-chevron-down fa-lg me-1"></i>{forum.downvotes}
                    </Col>

                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-comment me-1 ms-3"></i> {state.totalComments} Comments
                    </Col>

                    <Col className='col-auto'>
                    <div className="btn-group dropend">
                        <button type='button' className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-share me-1"></i>
                        Share
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedForumDetailUrl}&quote=ComeJoinUs`} target="_blank" className='dropdown-item'><i className="fa-brands fa-facebook pe-2"></i>Facebook</a></li>
                            <li><a href={`https://twitter.com/intent/tweet?url=${encodedForumDetailUrl}&text=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-twitter pe-2"></i>Twitter</a></li>
                            <li><a href={`https://www.reddit.com/submit?url=${encodedForumDetailUrl}&title=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-reddit pe-2"></i>Reddit</a></li>
                            <li> <hr className="dropdown-divider"></hr></li>
                            <li><a className="dropdown-item hand-cursor" onClick={handleCopyLink}><i className="fa-solid fa-copy pe-2"></i>Copy Link</a></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    </div>;
};

export default ForumCard;