import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import Forum from '../../Models/Forum';
import useApi from '../../Utils/useApi';
import AvatarImage from '../common/AvatarImage';
import ThumbnailImage from '../common/ThumbnailImage';

export interface ForumHeaderProps {
    className?: string;
    forum: Forum;
    getForum?: () => void;
    totalComments?: number;
};

const GameHeader: React.FC<ForumHeaderProps> = (props: ForumHeaderProps) => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const { forum } = props;
    const api = useApi();
    const navigate = useNavigate();

    const [gameName, setGameName] = React.useState('');
    React.useEffect(() => {
        (async () => {
            if(forum.gameId){
            const res = await api.get(`forums/${forum.id}/gamename`);
            setGameName(res);}
        })();
    }, []);
    const handleUpvote = React.useCallback(async () => {
        if (props.getForum) {
            try {
                await api.post(`forums/${forum.id}/upvote`);
                props.getForum();
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        }
    }, [api, props]);

    const handleDownvote = React.useCallback(async () => {
        if (props.getForum) {
            try {
                await api.post(`forums/${forum.id}/downvote`);
                props.getForum();
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        }
    }, [api, props]);

    const gameDetailUrl = `www.mudmunity.com/forums/${forum.id}`;
    const encodedForumDetailUrl = encodeURIComponent(gameDetailUrl);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(gameDetailUrl);
      };

    var addForumClick = React.useCallback(async (e: any) => {
        if (!userAuth?.userAccount) {
            const message = encodeURIComponent('Please sign in to create forum');
            const redirectUrl = encodeURIComponent(`/forums/create`);

            navigate(`/users/signin?message=${message}&redirectUrl=${redirectUrl}`);
        }
    }, [api]);

    return <div className={`lightblack pb-3 pt-3 ${props.className}`}>
        <div className='d-flex detailHeader'>
            <div>
                <ThumbnailImage forumId={forum.id} className='forumdetailPic'/>
            </div>
            <div>
                    <div className="px-0">
                        <h1 className="text-primary pt-2">{forum.title}</h1>
                    </div>

                    <div className="pb-2">
                            <h5>
                                <AvatarImage userId={forum.createdById ?? 0} className="mx-2 smallAvatar" />

                                <span className="brightred">
                                    {forum.createdBy?.username ?? forum.createdBy?.emailAddress}
                                </span>
                            </h5>

                            <h6 className="text-muted mt-1 ps-5">On {new Date(forum.createdOn).toLocaleDateString()}</h6>
                    </div>
                    <div className="pt-3">
                        <div ><h5 className="text-warning">Category:</h5></div>

                        <div>
                            <h5 >{forum.category}</h5>
                        </div>
                    </div>
                    <div className="pt-3">
                        <div><h5 className="text-warning" >Game:</h5></div>
                        <div>
                            <h5 >{gameName}</h5>
                        </div>
                    </div>
                    <div>


                    <div className='d-flex align-items-center mt-4'>
                        <div className='hand-cursor me-3'>
                            <div className="border py-1 rounded-pill d-flex ps-1 pe-3 align-items-center">
                                <Button color="link" onClick={handleUpvote}>
                                    <i className="fa-solid fa-chevron-up fa-lg me-1" />
                                </Button>
                                {forum.upvotes}
                                <Button color="link" onClick={handleDownvote}>
                                    <i className="fa-solid fa-chevron-down fa-lg ms-2" />
                                </Button>
                                {forum.downvotes}
                            </div>
                        </div>

                        <div className='hand-cursor me-3'>
                            <div className="border rounded-pill py-2 d-flex px-3 align-items-center">
                                <i className="fa-solid fa-comment my-1 me-2 ps-1"></i> {props.totalComments?? "Comments"}
                            </div>
                        </div>

                        <div className='hand-cursor me-3'>
                            <div className="btn-group dropend border rounded-pill py-2 d-flex px-3 align-items-center">
                                <div className="hand-cursor dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i className="fa-solid fa-share me-1"></i>
                                Share
                                </div>
                                <ul className="dropdown-menu dropdown-menu-right">
                                    <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedForumDetailUrl}&quote=ComeJoinUs`} target="_blank" className='dropdown-item'><i className="fa-brands fa-facebook pe-2"></i>Facebook</a></li>
                                    <li><a href={`https://twitter.com/intent/tweet?url=${encodedForumDetailUrl}&text=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-twitter pe-2"></i>Twitter</a></li>
                                    <li><a href={`https://www.reddit.com/submit?url=${encodedForumDetailUrl}&title=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-reddit pe-2"></i>Reddit</a></li>
                                    <li> <hr className="dropdown-divider"></hr></li>
                                    <li><a className="dropdown-item hand-cursor" onClick={handleCopyLink}><i className="fa-solid fa-copy pe-2"></i>Copy Link</a></li>
                                </ul>
                            </div>
                        </div>

                            {props.getForum && <div className='hand-cursor'>
                            <Link className="border rounded-pill py-2 d-flex px-3 align-items-center text-decoration-none text-light" to={`/forums/create/${forum.id}`} onClick={addForumClick}>
                                Create Forum
                            </Link>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
        
        
        
    </div>;
};

export default GameHeader;
