import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { CurrentUserContext } from '../../contexts/AuthContext';
import useApi from '../../Utils/useApi';

/**
 * The Sign out page, tells user they are signed out and gives
 * them an opportunity to sign out all remaining devices before
 * leaving the page
 * 
 * @returns SignOutPage
 */
const SignOutPage: React.FC = () => {
    const api = useApi();
    const { userAuth, setUserAuth } = React.useContext(CurrentUserContext);
    const redirect = useNavigate();

    /**
     * Makes the API call to sign out all refresh tokens and then
     * redirects to home page
     */
    const signOutAll = React.useCallback(() => {
        (async () => {
            await api.signOutAll();
        })();

        redirect('/');
    }, [redirect]);

    /**
     * Sign out this device
     */
    React.useEffect(() => {
        (async () => {
            try {
                if (userAuth?.userAccount) {
                    await api.signOut();
                    setUserAuth(undefined);
                }
            } catch { }
        })();
    }, [userAuth]);

    return <Container className="mb-5">
        <h1 className="mt-3">You have been signed out</h1>
        <div className="mb-5">
            We have signed you out on this device, however we do allow our users to sign in from
            multiple devices. If you would like you can use the button below to sign out all your
            other devices now as well. Or you can return to the home page.
        </div>

        <div className="text-end pe-5">
            <Button className="me-3 btn-danger" onClick={signOutAll}>Sign out all devices</Button>
            <Link className="btn btn-primary" to="/">Back to Home</Link>
        </div>
    </Container>;
};

export default SignOutPage;
