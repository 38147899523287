import * as React from 'react';
import * as Utils from '../../Utils';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import TextInput from '../common/TextInput';
import { ApiException } from '../../Api';
import useApi from '../../Utils/useApi';
import ResetPassword from '../../Commands/ResetPassword';

interface ResetPasswordPageState {
    err?: string;
    fieldErrors: any;
    formData: ResetPassword;
}

const ResetPasswordPage: React.FC = () => {
    const [qs] = useSearchParams();
    const api = useApi();
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            emailAddress: qs && qs.has('emailAddress') ? qs.get('emailAddress') : '',
            password: '',
            verificationCode: qs && qs.has('verificationCode') ? qs.get('verificationCode') : ''
        }
    } as ResetPasswordPageState);

    const { fieldErrors, formData } = state;

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const postForm = React.useCallback(async () => {
        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            console.log(formData);
            const user = await api.post('/users/resetPassword', formData);
            navigate('/users/signin');
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        postForm();
    }, [api]);

    return <Container>
        <Card className="UsersForm">
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Reset your password</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        Congratulations on taking the first step to regain control of your Mudmunity account! To complete the password reset process,
                        follow the steps below:

                        <ol>
                            <li>
                                Check your email: We've sent you a verification code to the email address associated with your account. Retrieve the
                                code from your inbox (including spam or junk folder) and enter it below.
                            </li>

                            <li>
                                Enter the verification code: Copy and paste the verification code you received into the designated field below. This
                                code ensures the security of your account and confirms your identity.
                            </li>

                            <li>
                                Choose a new password: Create a strong and memorable password for your account. It should be unique, containing a
                                combination of uppercase and lowercase letters, numbers, and special characters.
                            </li>
                        </ol>

                        By resetting your password, you'll regain access to your Mudmunity account, enabling you to resume your heroic adventures
                        and connect with fellow gamers. Embrace this fresh start, wield your renewed power, and embark on a thrilling journey once again!
                    </div>

                    <TextInput
                        error={fieldErrors['EmailAddress']}
                        label="Email Address *"
                        name="emailAddress"
                        onChange={handleFormChange}
                        tooltip="The email address that you used to create your account"
                        value={formData.emailAddress}
                    />

                    <TextInput
                        error={fieldErrors['VerificationCode']}
                        label="Verification Code *"
                        name="verificationCode"
                        onChange={handleFormChange}
                        tooltip="The verification code we sent via email"
                        value={formData.verificationCode}
                    />

                    <TextInput
                        error={fieldErrors['Password']}
                        isPassword={true}
                        label="New Password *"
                        name="password"
                        onChange={handleFormChange}
                        tooltip="The new password you want to use when signing in"
                        value={formData.password}
                    />
                </CardBody>

                <CardFooter className="text-end">
                    <button className="btn btn-primary">Verify Email</button>
                </CardFooter>
            </form>
        </Card >
    </Container>;
};

export default ResetPasswordPage;
