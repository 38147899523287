import * as React from 'react';
import * as Utils from '../../Utils';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { VerifyEmailAddress } from '../../Commands/VerifyEmailAddress';
import TextInput from '../common/TextInput';
import { ApiException } from '../../Api';
import useApi from '../../Utils/useApi';

interface VerifyEmailAddressPageState {
    err?: string;
    fieldErrors: any;
    formData: VerifyEmailAddress;
}

const VerifyEmailAddressPage: React.FC = () => {
    const [qs] = useSearchParams();
    const api = useApi();
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            emailAddress: qs && qs.has('emailAddress') ? qs.get('emailAddress') : '',
            verificationCode: qs && qs.has('verificationCode') ? qs.get('verificationCode') : ''
        }
    } as VerifyEmailAddressPageState);

    const { fieldErrors, formData } = state;

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const postForm = React.useCallback(async () => {
        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            const user = await api.post('/users/verifyEmail', formData);
            navigate('/users/signin');
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        postForm();
    }, [api]);

    React.useEffect(() => {
        if (qs && qs.has('emailAddress') && qs.has('verificationCode'))
            postForm();
    }, []);

    return <Container>
        <Card className="UsersForm">
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Verify your email address</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        Congratulations on creating your account! We're almost there! Before you can access your account,
                        we kindly ask you to verify your email address. We've sent you an email with a verification code.
                        Please check your inbox (including the spam or junk folder) and enter the code in the text box below.
                        Once verified, you'll gain full access to your account and begin your exciting MUD gaming journey!
                    </div>

                    <TextInput
                        error={fieldErrors['emailAddress']}
                        label="Email Address"
                        name="emailAddress"
                        onChange={handleFormChange}
                        tooltip="The email address that you used to create your account"
                        value={formData.emailAddress}
                    />

                    <TextInput
                        error={fieldErrors['verificationCode']}
                        label="Verification Code"
                        name="verificationCode"
                        onChange={handleFormChange}
                        tooltip="The verification code we sent via email"
                        value={formData.verificationCode}
                    />
                </CardBody>

                <CardFooter className="text-end">
                    <button className="btn btn-primary">Verify Email</button>
                </CardFooter>
            </form>
        </Card >
    </Container>;
};

export default VerifyEmailAddressPage;
