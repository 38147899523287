import * as React from 'react';
import * as Utils from '../../Utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import CreateForum from '../../Commands/CreateForum';
import useApi from '../../Utils/useApi';
import TextInput from '../common/TextInput';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import { ApiException } from '../../Api';
import EditForum from '../../Commands/EditForum';
import Forum from '../../Models/Forum';
import ImageInput from '../common/ImageInput';
import Game from '../../Models/Game';
import GameHeader from '../Game/GameHeader';
import { CurrentUserContext } from '../../contexts/AuthContext';
import getMutedDurationString from '../../Utils/MutedTillDateFormat';
import FadeModal from '../common/FadeModal';

export interface ForumFormProps {
    /**
     * Unique id of the forum thread to edit (if undefined we create new)
     */
    forumId?: number;

    /**
     * Unique id of the game that this new thread should apply to
     * (only applied if forumId is undefined)
     */
    gameId?: number;
};

interface ForumFormState {
    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The CreateUserAccount command our form is building
     */
    formData: CreateForum | EditForum;

    /**
     * If we are in edit mode, this is the forum we are editing
     */
    forum?: Forum;

    /**
     * The forum categories available
     */
    forumCategories: string[];

    /**
     * If we are creating a new thread on a game, this is the game
     * we are creating our thread on
     */
    game?: Game;
}

const ForumForm: React.FC<ForumFormProps> = (props: ForumFormProps) => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const [showModal, setShowModal] = React.useState(userAuth?.userAccount?.isMuted == true? true: false);
    /**
     * Initialize our state object
     */
    const [state, setState] = React.useState({
        err: '',
        fieldErrors: {},
        forumCategories: [],
        formData: {
            category: '',
            post: '',
            thumbnailImage: '',
            title: ''
        } as CreateForum
    } as ForumFormState);

    const api = useApi();
    const navigate = useNavigate();

    React.useEffect(() => {
        (async () => {
            var categories = await api.get('forums/categories');
            var forum: Forum | undefined = undefined;
            var game: Game | undefined = undefined;

            if (props.forumId)
                forum = await api.get(`/forums/${props.forumId}`);

            if (props.gameId)
                game = await api.get(`/games/${props.gameId}`);

            var formData: EditForum = {
                category: forum?.category ?? '',
                gameId: game?.id,
                post: forum?.post ?? '',
                title: forum?.title ?? ''
            }

            setState({
                ...state,
                formData,
                forum,
                forumCategories: categories,
                game
            });
        })();
    }, []);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            var thread: Forum;

            if (props.forumId)
                thread = await api.put(`/forums/${props.forumId}`, formData);
            else
                thread = await api.post('/forums', formData);
            
            navigate(`/forums/${thread.id}`);
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }

    }, [api, state]);

    const { forum } = state;

    console.log('state.formData:', state.formData);
console.log('state.formData.title:', state.formData.title);
    return <div>
        {state.game && <GameHeader className="mb-4" game={state.game} />}

        <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>
                        {forum ? 'Edit Forum Thread' : 'Create Forum Thread'}
                    </CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        Welcome to the realm of limitless ideas and boundless conversations! Take the stage and create a new forum thread to
                        share your thoughts, insights, and wild theories. From epic quests to game strategies, lore exploration to role-playing
                        tales, let your words resonate and spark a vibrant community of avid MUD gamers.
                    </div>

                    <TextInput
                        error={state.fieldErrors['Title']}
                        label="Thread title *"
                        name="title"
                        onChange={handleFormChange}
                        tabIndex={1}
                        tooltip="The title of this thread"
                        value={state.formData.title}
                    />

                    <SelectInput
                        error={state.fieldErrors['Category']}
                        label="Thread Category"
                        name="category"
                        onChange={handleFormChange}
                        options={state.forumCategories.map(v => ({ value: v } as SelectInputOption))}
                        tabIndex={2}
                        tooltip="Optionally you can select a category for this forum thread"
                        value={state.formData.category}
                    />

                    <ImageInput
                        error={state.fieldErrors['ThumbnailImage']}
                        label="Thumbnail Image"
                        name="thumbnailImage"
                        onChange={handleFormChange}
                        tabIndex={2}
                        tooltip="Optionally you can provide a custom thumbnail image"
                    />

                    <TextInput
                        error={state.fieldErrors['Post']}
                        isLongText={true}
                        label="Thread post *"
                        name="post"
                        onChange={handleFormChange}
                        tabIndex={3}
                        tooltip="This is where you place your actual post content"
                        value={state.formData.post}
                    />

                    <div className="text-end text-danger">
                        &nbsp;
                        {state.err as string}
                    </div>
                </CardBody>

                <CardFooter className="text-end">
                    {
                        showModal ? <button className="btn btn-primary" tabIndex={4} data-bs-toggle="modal" data-bs-target="#MutedModal">{forum ? 'Edit Forum Thread' : 'Create Forum Thread'}</button> : <button className="btn btn-primary" tabIndex={4}>
                        {forum ? 'Edit Forum Thread' : 'Create Forum Thread'}
                    </button>
                    }
                    <FadeModal id="MutedModal" headerTitle="You Are Muted"><span className='fw-bold'>You have been muted for {userAuth?.userAccount?.mutedTillDate && getMutedDurationString(userAuth?.userAccount?.mutedTillDate)} For the following reason:</span> <div>{userAuth?.userAccount?.muteReason}</div></FadeModal>
                </CardFooter>
            </form>
        </Card>
    </div>;
};

export default ForumForm;
