import * as React from "react";
import { Button, Card, CardFooter, CardSubtitle, CardText, CardTitle, Col, Row } from "reactstrap";
import { ApiException } from "../../Api";
import useApi from '../../Utils/useApi';
import { GameComment } from "../../Models/GameComment";
import GameCommentSection from "./GameCommentSection";
import AvatarImage from "../common/AvatarImage";

export interface GameCommentCardProps{
    comment: GameComment;
    depth: number;
    replyTo: string;
}
interface GameCommentCardState {
  comment: GameComment;
  showReplies: boolean;
  showCommentBar: boolean;
  depth: number;
  replyTo: string;
}
const GameCommentCard: React.FC<GameCommentCardProps> = (props) => {

    const api = useApi();

    const [state, setState] = React.useState({
      comment: props.comment,
      showReplies: true,
      showCommentBar: false,
      depth: props.depth,
      replyTo: props.replyTo
    }as GameCommentCardState);

    const handleMinusButtonClick = () => {
        setState({
          ...state,
          showReplies: !state.showReplies
        })
    };

    const handleCommentUpvote = async () => {
      try {
        await api.post(`games/comments/${state.comment.id}/upvote`);
        getGameComment(state.comment.id);
      } catch (err: ApiException | Error | string | any) {
        console.log(err);
      }
    };
  
    const handleCommentDownvote = async () => {
      try {
        await api.post(`games/comments/${state.comment.id}/downvote`);
        getGameComment(state.comment.id);
      } catch (err: ApiException | Error | string | any) {
        console.log(err);
      }
    };

    const getGameComment = React.useCallback(async (commentId: number) => {
      try {
        const updatedComment = await api.get(`games/comments/${commentId}`);
        setState({
          ...state,
          comment: updatedComment,
          showCommentBar: state.showCommentBar,
          showReplies: state.showReplies
        })
        
      } catch (err: ApiException | Error | string | any) {
        console.log(err);
      }
    }, [api, state.comment.id]);
    
    return <Card className="commentcard border-start border-bottom" id={`comment_${state.comment.id}`}>
        <Row>
          <Col xs={1}>
            <div className='text-center'>
              <AvatarImage className="img-fluid rounded-circle ms-3 mt-3" userId={state.comment.createdBy.id}/>
            </div>
          </Col>
          <Col xs={11}>
            <CardTitle className="mb-1">
              <Row>
                <Col className="text-start mt-3">
                  <Row>
                    <Col className="col-auto pb-0">
                      <h6 className="">{state.comment.createdBy?.username ?? state.comment.createdBy?.emailAddress}</h6>
                    </Col>
                    <Col className="col-auto text-muted pb-0">
                      <h6>On {new Date(state.comment.createdOn).toLocaleDateString()}</h6>
                    </Col>
                  </Row>
                </Col>
                <Col className="text-end me-3">
                  <i className="fa-solid fa-ellipsis"></i>
                </Col>
              </Row>
            </CardTitle>
            <CardText className="mb-2 me-3 post-content">
              <span className="fw-bold">Replying to:</span> <a href={`#comment_${props.comment.gamecommentId}`}><span className="text-warning ps-1 pe-2">{state.replyTo}</span></a>{state.comment.content}
            </CardText>
          </Col>
          <CardSubtitle className="mt-1">
              <div className="d-flex align-items-center">
                <div>
                  {state.showReplies && state.comment.gamecommentResponses && state.comment.gamecommentResponses.length > 0 && state.depth<=3 && (
                    <Button variant="outline-secondary" className="minus-button ms-4" onClick={handleMinusButtonClick}>
                      -
                    </Button>
                  )}
                  {!state.showReplies && state.depth<=3 &&(
                    <Row>
                      <Col className="col-auto">
                        <Row>
                          <Col className="col-auto">
                            <Button variant="outline-secondary" className="minus-button ms-4" onClick={handleMinusButtonClick}>
                              +
                            </Button>
                          </Col>
                          <Col className="col-auto">
                            <small className="text-muted">Show replies</small>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
                <div>
                <Row>
                    <Col className='col-auto'>
                      <Button color="link" onClick={handleCommentUpvote} className="ms-3">
                        <i className="fa-solid fa-chevron-up fa-lg me-1"></i>
                      </Button>
                      {state.comment.upvotes}
                    </Col>
                    <Col className='col-auto'>
                        <Button color="link" onClick={handleCommentDownvote}>
                          <i className="fa-solid fa-chevron-down fa-lg me-1"></i>
                        </Button>
                        {state.comment.downvotes}
                    </Col>
                  </Row>
                </div>
                <div>
                <Button onClick={() => setState({...state, showCommentBar: !state.showCommentBar})} className="btn rounded-pill commentBtn lightblack ms-4">Reply</Button>
                </div>
              </div>
            </CardSubtitle>
            <CardFooter>
              {state.showCommentBar && <GameCommentSection buttonname="Reply" gamecommentId={state.comment.id}/>}
            </CardFooter>
        </Row>
        {state.depth>=4 && state.showReplies && state.comment.gamecommentResponses && state.comment. gamecommentResponses.length > 0 && (
          <Row>
            <Col xs={12}>
              <hr className="m-0 p-0"/>
              {state.comment.gamecommentResponses.map((responseComment) => (
                <GameCommentCard key={responseComment.id} depth={state.depth} comment={responseComment} replyTo={state.comment.createdBy.username ?? state.comment.createdBy.emailAddress}/>
              ))}
            </Col>
            
          </Row>
        )}
        
        {state.depth<=3 && state.showReplies && state.comment.gamecommentResponses && state.comment.gamecommentResponses.length > 0 && (
        <Row>
          <Col xs={1}></Col>
          <Col xs={11}>
          <hr className="m-0 p-0"/>
            {state.comment.gamecommentResponses.map((responseComment) => (
              <GameCommentCard key={responseComment.id} depth={state.depth + 1} comment={responseComment} replyTo={state.comment.createdBy.username ?? state.comment.createdBy.emailAddress}/>
            ))}
          </Col>
        </Row>
      )}
      </Card>
  };
  
export default GameCommentCard;
