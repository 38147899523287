import * as React from 'react';
import * as Utils from '../../Utils';
import { ApiException } from '../../Api';
import Game from '../../Models/Game';
import { Button, Card, CardHeader, Col, Container, Row } from 'reactstrap';
import SortBar from '../common/SortBar';
import GameCard from './GameCard';
import useApi from '../../Utils/useApi';
import { CurrentUserContext } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import StarRatingInput from '../common/StarRatingInput';
import { ListGames } from '../../Commands/ListGames';
import TextInput from '../common/TextInput';
import FadeModal from '../common/FadeModal';
import getMutedDurationString from '../../Utils/MutedTillDateFormat';

interface GameIndexPageState {
    games?: Game[];
    errors?: ApiException | Error | string | any;
    isLoading: boolean;
    formData: ListGames;
    gameGenres: SelectInputOption[];
    gamePopulation: SelectInputOption[];
    gameStatusType: SelectInputOption[];
}

const GameIndexPage: React.FC = () => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const api = useApi();
    const navigate = useNavigate();
    const [showModal, setShowModal] = React.useState(userAuth?.userAccount?.isMuted == true? true: false);

    const [state, setState] = React.useState({
        fieldErrors: {},
        isLoading: true,
        errors: '',
        formData: {
            starRating: 0,
            sortAscending: false,
            sortBy: '',
            search: ''
        }as ListGames,
        gameGenres: [],
        gamePopulation: [],
        gameStatusType: [],
    } as GameIndexPageState);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
        getGames();
    }, [state]);

    const handleStarChange = React.useCallback((value: number) => {
        Utils.handleFilterChange(state, setState, 'averageStars', value);
    }, [state, setState]);

    React.useEffect(() => {
        const getGames = async () => {
            try {
                const genreRes = await api.get('games/genres');
                const gameGenres = genreRes.map((v: string) => ({ value: v } as SelectInputOption));
                const populationRes = await api.get('games/populations');
                const gamePopulation = populationRes.map((v: string) => ({ value: v } as SelectInputOption));
                const statusTypeRes = await api.get('games/statustypes');
                const gameStatusType = statusTypeRes.map((v: string) => ({ value: v } as SelectInputOption));
                const games = await api.get('games', state.formData);

                console.log(state.formData);

                setState({
                    ...state,
                    games: games,
                    gameGenres: gameGenres,
                    gamePopulation: gamePopulation,
                    gameStatusType: gameStatusType,
                    isLoading: false
                });
            }
            catch (err: ApiException | Error | any) {
                setState({
                    ...state,
                    errors: err
                });
            }
        };
        getGames();
    }, [api, setState]);

    var addGameClick = React.useCallback(async (e: any) => {
        if (!userAuth?.userAccount) {
            const message = encodeURIComponent('Please sign in to create game');
            const redirectUrl = encodeURIComponent(`/games/create`);

            navigate(`/users/signin?message=${message}&redirectUrl=${redirectUrl}`);
        } else {
            if(!userAuth?.userAccount?.isMuted){
                navigate('/games/create');  
            }
        }
    }, [userAuth])

    const setSortBy = React.useCallback((value: string) => {
        Utils.handleFormChange(state, setState, 'sortBy', value);
        getGames();
    }, [state]);

    const getGames = React.useCallback(() => {
        (async () => {
            var games = await api.get('games', state.formData);
            setState({ ...state, games });
        })();
    }, [state]);

    return <>
        {state.isLoading && <strong>Loading...</strong>}
        <div className="gameindex p-3 index">
            {/* Filter bar start*/}
            <div className='filter-bar'>
                {
                showModal ? <Button className='btn btn-warning mb-4 btnfont' onClick={addGameClick} data-bs-toggle="modal" data-bs-target="#MutedModal"><h5>Start a Game</h5></Button> : <Button className='btn btn-warning mb-4 btnfont' onClick={addGameClick}>
                <h5>Start a Game</h5>
                </Button>
                }
                <FadeModal id="MutedModal" headerTitle="You Are Muted"><span className='fw-bold'>You have been muted for {userAuth?.userAccount?.mutedTillDate && getMutedDurationString(userAuth?.userAccount?.mutedTillDate)} For the following reason:</span> <div>{userAuth?.userAccount?.muteReason}</div></FadeModal>
                

                <Card>
                    <ul className="list-group list-group-flush">
                        <CardHeader className="list-group-item mb-3">
                            <i className="me-3 fas fa-comments"></i><span className='tagmainfont'> All Games</span>
                        </CardHeader>
                        <div>
                            <SelectInput
                                error={undefined}
                                label="By Status"
                                name="StatusType"
                                noneLabel="(ALL)"
                                onChange={handleFormChange}
                                options={state.gameStatusType}
                                tabIndex={1}
                                tooltip="Optionally you can select a status for this game"
                                value={state.formData.statusType}
                            />

                            <SelectInput
                                error={undefined}
                                label="By Genre"
                                name="Genre"
                                noneLabel="(ALL)"
                                onChange={handleFormChange}
                                options={state.gameGenres}
                                tabIndex={2}
                                tooltip="Optionally you can select a genres for this game"
                                value={state.formData.genres}
                            />
                            <SelectInput
                                error={undefined}
                                label="By Population"
                                name="Population"
                                noneLabel="(ALL)"
                                onChange={handleFormChange}
                                options={state.gamePopulation}
                                tabIndex={1}
                                tooltip="Optionally you can select a population for this game"
                                value={state.formData.population}
                            />

                        </div>
                    </ul>
                </Card>
            </div>
            {/* Filter bar end*/}

            {/* Main display start */}
            <div className='main-display px-3'>
                {/* Sort by start */}
                <div className="d-flex justify-content-between mb-4">
                    <form action="">
                        <div className="input-group rounded">
                            <input
                                type="search"
                                className="form-control rounded"
                                placeholder="Search by name"
                                onChange={(e) => handleFormChange('search', e.target.value)}
                                value={state.formData.search}
                                name="search"
                            />
                        </div>
                    </form>
                    <SortBar sortParameters={["Upvotes", "ModifiedOn", "CreatedOn"]} onChange={setSortBy} />
                </div>
                {/* Sort by end */}

                        {state.games?.map(game => <Col xs={12}>
                            <GameCard game={game} onGenreSelected={handleFormChange} onPopulationSelected={handleFormChange} onStatusSelected={handleFormChange}/>
                        </Col>)}
                </div>
                {/* Main display end */}
        </div>

    </>
}
export default GameIndexPage;