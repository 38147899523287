import * as React from 'react';

export interface ThumbnailImageProps {
    className?: string;
    forumId: number;
};

const ThumbnailImage: React.FC<ThumbnailImageProps> = (props: ThumbnailImageProps) => {
    const src = `/images/forum/${props.forumId}/thumbnail`;

    return <img
        className={`img-fluid ${props.className}`}
        src={src}
    />;
};

export default ThumbnailImage;
