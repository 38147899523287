import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import Game from '../../Models/Game';
import useApi from '../../Utils/useApi';
import AvatarImage from '../common/AvatarImage';
import CoverImage from '../common/CoverImage';
import StarRating from '../common/StarRating';

export interface GameHeaderProps {
    className?: string;
    game: Game;
    getGame?: () => void;
    totalComments?: number;
};

const GameHeader: React.FC<GameHeaderProps> = (props: GameHeaderProps) => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const { game } = props;
    const api = useApi();
    const navigate = useNavigate();
    
    const handleUpvote = React.useCallback(async () => {
        if (props.getGame) {
            try {
                await api.post(`games/${game.id}/upvote`);
                props.getGame();
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        }
    }, [api, props]);

    const handleDownvote = React.useCallback(async () => {
        if (props.getGame) {
            try {
                await api.post(`games/${game.id}/downvote`);
                props.getGame();
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        }
    }, [api, props]);

    const websiteUrl = game.websiteUrl;
    const hasHttpProtocol = websiteUrl && (websiteUrl.startsWith('http://') || websiteUrl.startsWith('https://'));
    const fixedWebsiteUrl = hasHttpProtocol ? websiteUrl : websiteUrl ? `http://${websiteUrl}` : '';

    const gameDetailUrl = `www.mudmunity.com/games/${game.id}`;
    const encodedGameDetailUrl = encodeURIComponent(gameDetailUrl);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(gameDetailUrl);
      };

    var addForumClick = React.useCallback(async (e: any) => {
        if (!userAuth?.userAccount) {
            const message = encodeURIComponent('Please sign in to create forum');
            const redirectUrl = encodeURIComponent(`/forums/create`);

            navigate(`/users/signin?message=${message}&redirectUrl=${redirectUrl}`);
        }
    }, [api]);

    return <div className={`lightblack pb-3 pt-3 ${props.className}`}>
        <div className='d-flex detailHeader'>
            <div>
                <CoverImage gameId={game.id} className='gamedetailPic'/>
            </div>
            <div>
                    <div className="px-0">
                        <h1 className="text-primary pt-2">{game.name}</h1>
                    </div>

                    <div className="mb-2 ps-1">
                        <StarRating rating={game.averageStars || 0} />
                    </div>

                    <div className="pb-2">
                            <h5>
                                <AvatarImage userId={game.createdById ?? 0} className=" smallAvatar  mx-2" />

                                <span className="brightred">
                                    {game.createdBy?.username ?? game.createdBy?.emailAddress}
                                </span>
                            </h5>

                            <h6 className="text-muted mt-1 ps-5">On {new Date(game.createdOn).toLocaleDateString()}</h6>
                    </div>
                    <div className="pt-2">
                        <div ><h5 className="text-warning">Genre:</h5></div>

                        <div>
                            <h5 >{game.genre}</h5>
                        </div>
                        <div className="ps-5"><h5 className="text-warning">Population:</h5></div>

                        <div >
                            <h5 >{game.population}</h5>
                        </div>
                    </div>
                    <div className="pt-2">
                        <div><h5 className="text-warning" >Status:</h5></div>
                        <div>
                            <h5 >{game.status}</h5>
                        </div>
                    </div>
                    <div>
                        <a href={fixedWebsiteUrl} target="_blank" className=''><h5>{fixedWebsiteUrl}</h5></a>
                    </div>
            </div>
        </div>
        <div>
            <div className='d-flex align-items-center mt-3'>
                <div className='hand-cursor me-3'>
                    <div className="border py-1 rounded-pill d-flex ps-1 pe-3 align-items-center">
                        <Button color="link" onClick={handleUpvote}>
                            <i className="fa-solid fa-chevron-up fa-lg me-1" />
                        </Button>
                        {game.upvotes}
                        <Button color="link" onClick={handleDownvote}>
                            <i className="fa-solid fa-chevron-down fa-lg ms-2" />
                        </Button>
                        {game.downvotes}
                    </div>
                </div>

                <div className='hand-cursor me-3'>
                    <div className="border rounded-pill py-2 d-flex px-3 align-items-center">
                        <i className="fa-solid fa-comment my-1 me-2 ps-1"></i> {props.totalComments?? "Comments"}
                    </div>
                </div>

                <div className='hand-cursor me-3'>
                    <div className="btn-group dropend border rounded-pill py-2 d-flex px-3 align-items-center">
                        <div className="hand-cursor dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-share me-1"></i>
                        Share
                        </div>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedGameDetailUrl}&quote=ComeJoinUs`} target="_blank" className='dropdown-item'><i className="fa-brands fa-facebook pe-2"></i>Facebook</a></li>
                            <li><a href={`https://twitter.com/intent/tweet?url=${encodedGameDetailUrl}&text=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-twitter pe-2"></i>Twitter</a></li>
                            <li><a href={`https://www.reddit.com/submit?url=${encodedGameDetailUrl}&title=ComeJoinUs"`} className="dropdown-item" target="_blank"><i className="fa-brands fa-reddit pe-2"></i>Reddit</a></li>
                            <li> <hr className="dropdown-divider"></hr></li>
                            <li><a className="dropdown-item hand-cursor" onClick={handleCopyLink}><i className="fa-solid fa-copy pe-2"></i>Copy Link</a></li>
                        </ul>
                    </div>
                </div>

                    {!userAuth?.userAccount?.isMuted && (props.getGame && <div className='hand-cursor'>
                    <Link className="border rounded-pill py-2 d-flex px-3 align-items-center text-decoration-none text-light" to={`/forums/create/${game.id}`} onClick={addForumClick}>
                        Create Forum
                    </Link>
                </div>)}
            </div>
        </div>
        
        
    </div>;
};

export default GameHeader;
