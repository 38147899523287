import * as React from 'react';
import * as Utils from '../../Utils';

import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import ChangeUserPassword from '../../Commands/ChangeUserPassword';
import useApi from '../../Utils/useApi';
import TextInput from '../common/TextInput';
import { ApiException } from '../../Api';

interface ChangeUserPasswordState {
    /**
     * The change user password command (second form)
     */
    formData: ChangeUserPassword;

    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array
}

export interface ChangePasswordFormProps {
    userId: number;
}

/**
 * Simple form that allows the user to change their password
 * 
 * @param props Props
 * @returns ChangePasswordForm (Card)
 */
const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props: ChangePasswordFormProps) => {
    const { userId } = props;
    const [state, setState] = React.useState<ChangeUserPasswordState>({
        err: '',
        fieldErrors: {},
        formData: {
            confirmPassword: '',
            currentPassword: '',
            newPassword: ''
        } as ChangeUserPassword
    });

    const api = useApi();
    const redirect = useNavigate();

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            await api.post(`/users/${userId}/password`, formData);
            redirect(`/users/${userId}/profile`);
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    return <Card className="mt-5">
        <form onSubmit={handleSubmit}>
            <CardHeader className="bg-danger">
                <CardTitle>Change Password:</CardTitle>
            </CardHeader>

            <CardBody>
                <div className="mb-3">
                    Use this form to change your password
                </div>

                <TextInput
                    error={state.fieldErrors['currentPassword']}
                    isPassword={true}
                    label="Current Password *"
                    name="currentPassword"
                    onChange={handleFormChange}
                    tooltip="Provide your current password"
                    value={state.formData.currentPassword}
                />

                <TextInput
                    error={state.fieldErrors['newPassword']}
                    isPassword={true}
                    label="New Password *"
                    name="newPassword"
                    onChange={handleFormChange}
                    tooltip="Provide your new password"
                    value={state.formData.newPassword}
                />

                <TextInput
                    error={state.fieldErrors['confirmPassword']}
                    isPassword={true}
                    label="Confirm Password *"
                    name="confirmPassword"
                    onChange={handleFormChange}
                    tooltip="Provide your current password"
                    value={state.formData.confirmPassword}
                />

                <div className="text-end text-danger">
                    &nbsp;
                    {state.err as string}
                </div>
            </CardBody>

            <CardFooter>
                <Row>
                    <Col className="text-end">
                        <button className="btn btn-danger">Change Password</button>
                    </Col>
                </Row>
            </CardFooter>
        </form>
    </Card>;
};

export default ChangePasswordForm;
