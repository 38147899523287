import * as React from 'react';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import FeedBack from '../../Models/FeedBack';
import { Button } from 'reactstrap';
import * as Utils from '../../Utils';

interface PendingFeedBackState {
    feedback: FeedBack[];
}
const PendingFeedBack: React.FC = () => {

    const [state, setState] = React.useState({feedback: []}as PendingFeedBackState);
    const api = useApi();

    const handleResolve = async (feedbackId:number) => {
        try {
          await api.put(`users/feedbacks/${feedbackId}`);
          getFeedback(feedbackId);
        } catch (err: ApiException | Error | string | any) {
          console.log(err);
        }
      };

      React.useEffect(() => {
        getFeedback();
    }, [api, setState]);

      const getFeedback = React.useCallback(async (feedbackId?: number) => {
        try {
            const feedbacks = await api.get("users/feedbacks");
            setState({
                ...state,
                feedback: feedbacks,
            });
          
        } catch (err: ApiException | Error | string | any) {
          console.log(err);
        }
      }, [api, setState]);

const unresolvedFeedback = state.feedback.filter((feedback) => !feedback.isResolved);

return <div>
        <table className="table table-dark table-striped ">
            <thead>
                <tr>
                    <th scope="col">User Address</th>
                    <th scope="col">Message</th>
                    <th scope="col">PageUrl</th>
                    <th scope="col">Type</th>
                    <th scope="col">Resolve</th>
                </tr>
            </thead>
            <tbody>
                    {unresolvedFeedback.map((feedback) => (
                        <tr>
                            <td>{feedback.fromAddress}</td>
                            <td>{feedback.message}</td>
                            <td>{feedback.pageUrl}</td>
                            <td>{feedback.type}</td>
                            <td><Button className='btn p-0 m-0' onClick={() => handleResolve(feedback.id)}><i className="fa-solid fa-check"></i></Button></td>
                        </tr>
                    ))}
                </tbody>
        </table>
     </div>
}
export default PendingFeedBack;