import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container } from 'reactstrap';
import { CurrentUserContext } from '../../contexts/AuthContext';
import ForumForm from './ForumForm';

const CreateForumPage: React.FC = () => {
    const { forumId, gameId } = useParams();
    const { userAuth } = React.useContext(CurrentUserContext);
    if (!userAuth?.userAccount)
        window.location.href = `/users/signin?message=Please sign in to create forum threads&redirectUrl=${encodeURIComponent('/forums/create')}`;

    return <Container>
        <ForumForm forumId={Number(forumId)} gameId={Number(gameId)} />
    </Container>;
};

export default CreateForumPage;
