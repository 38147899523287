import * as React from 'react';
import * as Utils from '../../Utils';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { VerifyEmailAddress } from '../../Commands/VerifyEmailAddress';
import TextInput from '../common/TextInput';
import { ApiException } from '../../Api';
import useApi from '../../Utils/useApi';
import ForgotPassword from '../../Commands/ForgotPassword';

interface ForgotPasswordPageState {
    err?: string;
    fieldErrors: any;
    formData: ForgotPassword;
}

const ForgotPasswordPage: React.FC = () => {
    const [qs] = useSearchParams();
    const api = useApi();
    const navigate = useNavigate();

    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            emailAddress: qs && qs.has('emailAddress') ? qs.get('emailAddress') : ''
        }
    } as ForgotPasswordPageState);

    const { fieldErrors, formData } = state;

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const postForm = React.useCallback(async () => {
        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            const user = await api.post('/users/forgotPassword', formData);
            navigate('/users/resetPassword');
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        postForm();
    }, [api]);

    return <Container>
        <Card className="UsersForm">
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Reset your password</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        <h3>Step 1:</h3>
                        If you've forgotten your password, fear not! Simply enter your email address below, and we'll guide
                        you on the path to reset it. We will send an email with a new verification code, entering that verification
                        code here will grant you access to reclaim your MUD Games account. Get ready to dive back into captivating
                        adventures, wield your virtual powers once more, and continue your heroic journey. Don't let a forgotten
                        password hinder your epic saga, let's get you back on track!
                    </div>

                    <TextInput
                        error={fieldErrors['EmailAddress']}
                        label="Email Address"
                        name="emailAddress"
                        onChange={handleFormChange}
                        tooltip="The email address that you used to create your account"
                        value={formData.emailAddress}
                    />
                </CardBody>

                <CardFooter className="text-end">
                    <button className="btn btn-primary">Verify Email</button>
                </CardFooter>
            </form>
        </Card >
    </Container>;
};

export default ForgotPasswordPage;
