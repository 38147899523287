import { ApiClient } from "../Api";

/**
 * Point to the same protocol, hostname and port that the page is running
 * on plus /api/
 */
const baseUrl = `${window.location.protocol}//${window.location.host}/api/`;
const api = new ApiClient(baseUrl);

/**
 * Custom react hook to get our ApiClient
 * 
 * @returns ApiClient ready to use
 */
const useApi = (): ApiClient => {
    return api;
}

export default useApi;
