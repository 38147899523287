import * as React from 'react';
import CreateGameComment from '../../Commands/CreateGameComment';
import { Button, Container } from 'reactstrap';
import TextInput from '../common/TextInput';
import * as Utils from '../../Utils';
import { useParams } from 'react-router';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import FadeModal from '../common/FadeModal';
import getMutedDurationString from '../../Utils/MutedTillDateFormat';

export interface GameCommentSectionProps {
    gamecommentId?: number;
    buttonname: string;
}

const GameCommentSection: React.FC<GameCommentSectionProps> = (props) => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const [state, setState] = React.useState({
        gamecommentId: props.gamecommentId ?? null,
    }as CreateGameComment);
    const [showModal, setShowModal] = React.useState(userAuth?.userAccount?.isMuted == true? true: false);

    
    const api = useApi();
    const { id } = useParams();

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        const newState = { ...state } as any;
        newState[name] = value;
        setState(newState);
    }, [state]);
    
    const handleAddComment = async () => {
        try {
          await api.post(`games/${id}/comment`, state);
          window.location.reload();
        } catch (err: ApiException | Error | string | any) {
          console.log(err);
        }
      };


return <Container fluid={true} className="mb-4">
    <div>
        <TextInput
          placeholder="What do you think?"
          label=""
          name="content"
          onChange={handleFormChange}
          value={state.content}
          error={undefined}
          isLongText={true}
        />

        {
          showModal ? <Button onClick={handleAddComment} data-bs-toggle="modal" data-bs-target="#MutedModal">Add {props.buttonname}</Button> : <Button onClick={handleAddComment}>Add {props.buttonname}</Button>
        }
      <FadeModal id="MutedModal" headerTitle="You Are Muted"><span className='fw-bold'>You have been muted for {userAuth?.userAccount?.mutedTillDate && getMutedDurationString(userAuth?.userAccount?.mutedTillDate)} For the following reason:</span> <div>{userAuth?.userAccount?.muteReason}</div></FadeModal>
      </div>


        
 </Container> 
}
export default GameCommentSection;