import * as React from "react";
import { createContext } from "react";
import UserAccount from "../Models/UserAccount";
import UserAuthResult from "../Models/UserAuthResult";

/**
 * These are the values exposed by this provider
 */
interface CurrentUserValues {
    /**
     * JwtUserModel representing the signed in user
     */
    userAuth?: UserAuthResult;

    /**
     * State setter for current user
     */
    setUserAuth: React.Dispatch<React.SetStateAction<UserAuthResult | undefined>>;
}

/**
 * The actual context
 */
export const CurrentUserContext = createContext({} as CurrentUserValues);

/**
 * The provider to wrap consuming components in
 * 
 * @param props (The props)
 * @returns Provider for interacting with shared state for current user
 */
export const AuthProvider = (props: any) => {
    const [userAuth, setUserAuth] = React.useState<UserAuthResult>();

    if (window.sessionStorage.currentUser && !userAuth) {
        try {
            const bearerToken = window.sessionStorage.bearerToken;
            const refreshToken = window.localStorage.refreshToken;
            const userAccount: UserAccount = JSON.parse(window.sessionStorage.currentUser);

            setUserAuth({
                bearerToken,
                refreshToken,
                userAccount,
                userId: userAccount.id,
                isAdmin: userAccount?.siteRole === 'Admin',
                isMod: userAccount?.siteRole === 'Moderator'
            });
        } catch {
            window.sessionStorage.removeItem('bearerToken');
            window.sessionStorage.removeItem('currentUser');
            window.sessionStorage.removeItem('tokenExpires');
        }
    }

    return <CurrentUserContext.Provider value={{ userAuth, setUserAuth }}>
        {props.children}
    </CurrentUserContext.Provider>;
}
