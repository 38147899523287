/**
 * Helper to automatically update our state objects.  Expected that state provided contains:
 *   .err: string
 *   .fieldErrors: string[string]
 *   .formData: any[string]
 * 
 * @param state The state object to update
 * @param setState The setState method to invoke to apply these changes
 * @param name Name of the state.formData, to update (note state.fieldErrors[name] is blanked out too)
 * @param value The value to apply to this state.formData[name]
 */
const handleFormChange = <T>(state: T, setState: (value: React.SetStateAction<T>) => void, name: string, value?: any) => {
    const newState = { ...state } as any;
    newState.err = '';
    newState.fieldErrors[name] = '';
    newState.formData[name] = value;

    setState(newState);
};

export default handleFormChange;
