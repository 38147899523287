import * as React from 'react';
import * as Utils from '../../Utils';

import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import TextInput from '../common/TextInput';
import AuthUserAccount from '../../Commands/AuthUserAccount';
import useApi from '../../Utils/useApi';

/**
 * Custom state for the sign in Page
 */
interface SignInPageState {
    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The AuthUser command our form is building
     */
    formData: AuthUserAccount;
}

/**
 * Our sign in Page
 * 
 * @returns SignInPage
 */
const SignInPage: React.FC = () => {
    const [qs] = useSearchParams();
    const [state, setState] = React.useState({
        err: '',
        fieldErrors: {},
        formData: {
            username: '',
            password: ''
        } as AuthUserAccount
    } as SignInPageState);

    const { setUserAuth } = React.useContext(CurrentUserContext);

    const api = useApi();
    const navigate = useNavigate();

    const { formData, fieldErrors } = state;

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state, setState]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            var res = await api.signIn(formData);
            setUserAuth(res);

            if (qs && qs.has('redirectUrl')) {
                const redirectUrl = qs.get('redirectUrl') as string;

                navigate(redirectUrl.includes('%') ?
                    decodeURIComponent(redirectUrl) :
                    redirectUrl
                )
            } else {
                navigate('/');
            }
        } catch (err: ApiException | any) {
            setState({
                err: err.error,
                fieldErrors: err.fieldErrors ?? {},
                formData,
            });
        }

    }, [api, state, setState]);

    return <Container>
        <Card className="UsersForm">
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Sign In</CardTitle>
                </CardHeader>

                <CardBody>
                    {qs && qs.has('message') && <div className="mb-3 text-danger">
                        {qs.get('message')}
                    </div>}

                    <div className="mb-3 pb-2 border-bottom">
                        Enter the gateway to your virtual adventures. Sign in now to explore, interact, and conquer in the realm of MUD games!
                    </div>

                    <TextInput
                        error={fieldErrors['username']}
                        label="Email Address or Username"
                        name="username"
                        onChange={handleFormChange}
                        tooltip="The email address, or username that you used to create your account"
                        value={formData.username}
                    />

                    <TextInput
                        error={fieldErrors['password']}
                        isPassword={true}
                        label="Password"
                        name="password"
                        onChange={handleFormChange}
                        tooltip="The password that you used to create your account"
                        value={formData.password}
                    />
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col xs={4}>
                            <Link to="/users/signup">Create free account</Link>
                        </Col>

                        <Col xs={4} className="text-center">
                            <Link to="/users/forgotPassword">Forgot your password?</Link>
                        </Col>

                        <Col xs={4} className="text-end">
                            <button className="btn btn-primary" tabIndex={7}>Sign In</button>
                        </Col>
                    </Row>
                </CardFooter>
            </form>
        </Card >
    </Container>;
};

export default SignInPage;
