import moment from 'moment';
import * as React from 'react';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import UserAccount from '../../Models/UserAccount';
import AvatarImage from '../common/AvatarImage';

/**
 * The properties for a ProfileCard
 */
export interface ProfileCardProps {
    /**
     * The user account to display
     */
    user: UserAccount;
};

/**
 * Displays a user account card for the requested user
 * 
 * @param props The props
 * @returns ProfileCard widget
 */
const ProfileCard: React.FC<ProfileCardProps> = (props: ProfileCardProps) => {
    const { user } = props;
    const [activeButton, setActiveButton] = React.useState(0);

    return <Card>
        <CardHeader>
            <CardTitle>
                <div className="float-start me-3">
                    <AvatarImage userId={user.id ?? 0} className="smallAvatar" />
                </div>

                <div className="float-end">
                    <a href={`/users/${user.id}/update`}>
                        <i className="fa-solid fa-pencil"></i>
                    </a>
                </div>

                {user.username ?? 'Community Member'}
            </CardTitle>
        </CardHeader>

        <CardBody>
            <div className="pb-3">
                <h2>About Me:</h2>
                <Row>
                    <Col xs={12} lg={6}>
                        <div>{user.firstName} {user.lastName}</div>
                        <div>{user.emailAddress}</div>
                    </Col>

                    <Col xs={12} lg={6}>
                        <div>Member Since: {moment(user.createdOn).toString()}</div>
                        <div>Community Role: {user.siteRole}</div>
                    </Col>
                </Row>
            </div>
        </CardBody>

        <CardFooter>
            <h2>Socials:</h2>
            <Row>
                {user.facebookUsername && <Col xs={12} md={4}>
                    <a href={`https://facebook.com/${user.facebookUsername}`} target="_blank">
                        <i className="fa-brands fa-facebook pe-2"></i>
                        {user.facebookUsername}
                    </a>
                </Col>}

                {user.twitterHandle && <Col xs={12} md={4}>
                    <a href={`https://twitter.com/${user.twitterHandle}`} target="_blank">
                        <i className="fa-brands fa-twitter pe-2"></i>
                        {user.twitterHandle}
                    </a>
                </Col>}

                {user.redditUsername && <Col xs={12} md={4}>
                    <a href={`https://reddit.com/u/${user.redditUsername}`} target="_blank">
                        <i className="fa-brands fa-reddit pe-2"></i>
                        {user.redditUsername}
                    </a>
                </Col>}
            </Row>
        </CardFooter>
    </Card>;
};

export default ProfileCard;
