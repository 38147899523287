import jwt_decode from "jwt-decode";
import moment from "moment";
import AuthUserAccount from "../Commands/AuthUserAccount";
import RefreshBearerToken from "../Commands/RefreshBearerToken";
import UserAccount from "../Models/UserAccount";
import UserAuthResult from "../Models/UserAuthResult";
import ApiException from "./ApiException";

/**
 * ApiClient is a helper to communicate with the mudmunity api server
 */
export default class ApiClient {
    /**
     * Cheap fix for a bug, refreshing the token goes all inception and blows
     * up the networks. We use this to stop ourselves from getting silly
     */
    private isRefreshing: boolean = false;

    /**
     * Default constructor, accepts a rootUrl that following calls should
     * be based to. You should set this based on the environment you want
     * to target
     * 
     * @param rootUrl The rootUrl to use
     */
    constructor(private readonly rootUrl: string) {
        if (!rootUrl.endsWith('/')) {
            this.rootUrl = `${rootUrl}/`;
        }
    }

    /**
     * Clears our user session and local storage
     */
    public clearSession() {
        window.sessionStorage.removeItem('bearerToken');
        window.sessionStorage.removeItem('currentUser');
        window.sessionStorage.removeItem('tokenExpires');

        window.localStorage.removeItem('refreshToken');
        window.localStorage.removeItem('userId');
    }

    /**
     * Sets our users session and local storage values from the
     * provided authResult
     * 
     * @param authResult The UserAuthResult containing bearer, refresh and UserAccount
     */
    public setSession(authResult: UserAuthResult) {
        window.sessionStorage.bearerToken = authResult.bearerToken;
        window.sessionStorage.currentUser = JSON.stringify(authResult.userAccount);

        window.localStorage.refreshToken = authResult.refreshToken;
        window.localStorage.userId = authResult.userAccount?.id;

        const jwt = jwt_decode(authResult.bearerToken) as any;
        window.sessionStorage.tokenExpires = new Date(jwt.exp * 1000);
    }

    /**
     * Use this to check the current user's access token and see if they have
     * this permission
     * 
     * @param scope Scope of the permission to check for
     * @param name Name of the permission to check for
     * @returns boolean
     */
    public async hasPermission(scope: string, name: string): Promise<boolean> {
        const permissionId: number = await this.get(`/permission/${scope}/${name}`);
        const claims = this.parseJwt(window.sessionStorage.bearerToken);
        const permissions = claims['CurrentUser.Permissions'];

        if (!permissionId || permissionId === 0 || !claims || !permissions)
            return false;

        return permissions.some((c: string) => c == permissionId.toString());
    }

    /**
     * Helper to parse our jwt into a claims object
     * 
     * @param token The token we got when we signed in, or refreshed
     * @returns The claims object
     */
    private parseJwt(token: string): any {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    /**
     * Helper method to call an HTTP GET
     * 
     * @param path The path to GET (such as /games, or /users, etc)
     * @param query Optional object that gets serialized to query string parameters
     * @returns The response payload
     */
    public async get(path: string, query?: any): Promise<any> {
        const qs = this.serializeQs(query);
        const url = path + qs;
        return this.fetch('GET', url);
    }

    /**
     * Will refresh the bearer token now
     */
    public async refreshToken() {
        const refreshCommand: RefreshBearerToken = {
            refreshToken: window.localStorage.refreshToken,
            userId: window.localStorage.userId
        };

        if (!this.isRefreshing) {
            this.isRefreshing = true;

            try {
                const res = await this.post('/auth/refresh', refreshCommand) as UserAuthResult;
                this.setSession(res);
            } catch (err: any) {
                console.log(err);
                this.clearSession();
            }

            this.isRefreshing = false;
        }
    }

    /**
     * Underlying fetch call, customized to work with our api
     * 
     * @param method The HTTP verb / method
     * @param path The path to call
     * @param payload The payload to serialize (as json) to the body
     * @returns The response payload
     */
    public async fetch(method: string, path: string, payload?: any): Promise<any> {
        const url = this.buildUrl(path);
        const body = payload ? JSON.stringify(payload) : null;

        const req: RequestInit = {
            method,
            redirect: 'follow',
            headers: { "Content-Type": "application/json" },
            body: body
        };

        if (this.isLoggedIn()) {
            // If the bearer token is expired and this isn't the call to refresh it
            // we will automatically try to refresh
            if (
                moment(window.sessionStorage.tokenExpires) <= moment(new Date()) &&
                !path.endsWith('/auth/refresh')
            ) {
                this.refreshToken();
            }

            if (req && req.headers)
                req.headers = {
                    ...req.headers,
                    'Authorization': `Bearer ${window.sessionStorage.bearerToken}`
                };
        }

        const res = await fetch(url, req);

        if (res.redirected === true) {
            window.location.href = res.url;
            return undefined;
        }

        const data = await res.text();
        const responseType = res.headers.get('Content-Type');

        if (res.status >= 200 && res.status <= 299) {
            if (responseType && responseType.indexOf('application/json') >= 0) {
                return data ? await JSON.parse(data) : null;
            } else {
                return data;
            }
        }


        if (res.status >= 400 && res.status <= 599) {
            if (responseType && responseType.indexOf('application/json') >= 0) {
                const err = data ? await JSON.parse(data) : undefined;

                throw {
                    fieldErrors: err?.fieldErrors ?? [],
                    message: err?.message ?? err?.error ?? err,
                    statusCode: res.status ?? 500
                } as ApiException;
            } else {
                throw {
                    message: body,
                    statusCode: res.status
                } as ApiException;
            }
        }
    }

    public isLoggedIn() {
        if (window.sessionStorage.bearerToken)
            return true;
        return false;
    }

    /**
     * Helper method to call an HTTP POST
     * 
     * @param path The path to POST
     * @param payload Optional object that gets serialized to json in the request body
     * @returns The response payload
     */
    public async post(path: string, payload?: object): Promise<any> {
        return this.fetch('POST', path, payload);
    }

    /**
     * Helper method to cal an HTTP PUT
     * 
     * @param path The path to POST
     * @param payload Optional object that gets serialized to json in the request body
     * @returns The response payload
     */
    public async put(path: string, payload?: object): Promise<any> {
        return this.fetch('PUT', path, payload);
    }

    /**
     * Posts the provided auth user command, throws ApiException if invalid,
     * stores the necessary tokens locally and returns the UserAuthResult if successful
     * 
     * @param command The auth user command to sign in with
     * @returns UserAuthResult
     * @throws ApiError
     */
    public async signIn(command: AuthUserAccount): Promise<UserAuthResult | undefined> {
        const res = await this.post('/auth/signin', command) as UserAuthResult;
        this.setSession(res);
        return res;
    }

    /**
     * Sign out helper, will post to API asking to invalidate the current
     * refresh token, then removes bearer and refresh tokens from session storage
     */
    public async signOut() {
        try {
            if (window.localStorage.refreshToken) {
                await this.post(`/auth/signout/${encodeURIComponent(window.localStorage.refreshToken)}`);
            }
        } catch { }

        this.clearSession();
    }

    /**
     * Sign out helper, will post to API asking to invalidate all refresh tokens, 
     * then removes bearer and refresh tokens from session storage
     */
    public async signOutAll() {
        try {
            await this.post('/auth/signout/all');
        } catch { }

        this.clearSession();
    }

    /**
     * Helper to build the full url we will call
     * 
     * @param path The path we are trying to call
     * @returns The full url to call
     */
    protected buildUrl(path: string): string {
        return this.rootUrl + (path.startsWith('/') ? path.substring(1) : path);
    }

    /**
     * Helper to serialize the provided query object into a proper http
     * query string (encoded and all)
     * 
     * @param query The query object to serialize
     * @returns query string (with leading ?)
     */
    protected serializeQs(query: any): string {
        if (!query) return '';

        const q: { [key: string]: any } = query;

        return '?' + Object.keys(q)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(q[key])}`)
            .join('&');
    }
};
