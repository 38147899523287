import * as React from 'react';

export interface CoverImageProps {
    className?: string;
    gameId: number;
};

const CoverImage: React.FC<CoverImageProps> = (props: CoverImageProps) => {
    const src = `/images/game/${props.gameId}/cover`;

    return <img
        className={`img-fluid ${props.className}`}
        src={src}
    />;
};

export default CoverImage;
