import * as React from 'react';
import * as Utils from '../../Utils';
import CreateGame from '../../Commands/CreateGame';
import { Container, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import TextInput from '../common/TextInput';
import useApi from '../../Utils/useApi';
import { useNavigate } from 'react-router';
import { ApiException } from '../../Api';
import Game from '../../Models/Game';
import UpdateGame from '../../Commands/UpdateGame';
import ImageInput from '../common/ImageInput';
import GameForm from './GameForm';
import { CurrentUserContext } from '../../contexts/AuthContext';

const CreateGamePage: React.FC = () => {
    const { userAuth } = React.useContext(CurrentUserContext);
    if (!userAuth?.userAccount)
        window.location.href = `/users/signin?message=Please sign in to add games to the catalog&redirectUrl=${encodeURIComponent('/games/create')}`;

    return <Container>
        <GameForm />
    </Container>;
};

export default CreateGamePage;