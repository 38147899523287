import * as React from 'react';
import './TopNav.css'

import { Link, useLocation } from 'react-router-dom';
import { Collapse, Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar, NavbarBrand, NavbarText, NavbarToggler, NavItem, NavLink, UncontrolledDropdown } from 'reactstrap';
import { CurrentUserContext } from '../../contexts/AuthContext';

/**
 * Represents our top navigation menu
 * 
 * @returns TopNav component
 */
const TopNav: React.FC = () => {
    const [isOpen, setIsOpen] = React.useState(true);
    const location = useLocation();

    const { userAuth } = React.useContext(CurrentUserContext);
    const currentUser = userAuth?.userAccount;

    const toggleNav = React.useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen]);


    return <>
        <div className="navSocialBar black">
            <Container>
                <Navbar expand="md" className="py-0">
                    <div className="navbar-brand">
                        <a href="#" target="_blank"><i className="fa-brands fa-facebook" /></a>
                        <a href="#" target="_blank"><i className="fa-brands fa-twitter" /></a>
                        <a href="#" target="_blank"><i className="fa-brands fa-reddit" /></a>
                        <a href="https://discord.gg/qwz7JcTzYE" target="_blank"><i className="fa-brands fa-discord" /></a>
                    </div>
                </Navbar>
            </Container>
        </div>

        <div className='lightdark'>
            <Container>
                <Navbar expand="md" dark>
                    <NavbarBrand href="/">Mudmunity</NavbarBrand>
                    <NavbarToggler onClick={toggleNav} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav className="ms-auto" navbar>
                            <NavItem>
                                <Link to="/" className="nav-link">Home</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/games" className='nav-link'>Games</Link>
                            </NavItem>

                            <NavItem>
                                <Link to="/forums" className="nav-link">Forums</Link>
                            </NavItem>

                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret>Members</DropdownToggle>


                                {currentUser ?
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <Link to={`/users/${currentUser.id}/profile`} className="nav-link">My Profile</Link>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <Link to={`/users/bug?pageUrl=${encodeURIComponent(location.pathname)}`} className="nav-link">Report Bug</Link>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <Link to={`/users/feedback?pageUrl=${encodeURIComponent(location.pathname)}`} className="nav-link">Submit Feedback</Link>
                                        </DropdownItem>

                                        {
                                            (userAuth.isAdmin || userAuth.isMod) && <DropdownItem>
                                            <Link to={"/users/pendingfeedback"} className="nav-link">Pending Feedbacks</Link>
                                            </DropdownItem>
                                        }

                                        {
                                            (userAuth.isAdmin || userAuth.isMod) && <DropdownItem>
                                            <Link to={"/users/moderation"} className="nav-link">User Moderation</Link>
                                            </DropdownItem>
                                        }
                                        

                                        <DropdownItem divider />

                                        <DropdownItem>
                                            <Link to="/users/signout" className="nav-link">Sign Out</Link>
                                        </DropdownItem>
                                    </DropdownMenu> :
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <Link to="/users/signin" className="nav-link">Sign In</Link>
                                        </DropdownItem>

                                        <DropdownItem>
                                            <Link to="/users/signup" className="nav-link">Sign Up</Link>
                                        </DropdownItem>
                                    </DropdownMenu>
                                }
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Navbar>
            </Container>
        </div>
    </>;
};

export default TopNav;
