import * as React from 'react';

export interface SelectInputOption {
    label?: string;
    value: string;
}

/**
 * Custom properties for our SelectInput control
 */
interface SelectInputProps {
    /**
     * Disable the dropdown?
     */
    disabled?: boolean;

    /**
     * Error message to display for this dropdown
     */
    error?: string;

    /**
     * Label to display for this dropdown
     */
    label: string;

    /**
     * Name of the value this dropdown controls (used when dropdown calls onChange)
     */
    name: string;

    /**
     * Optional label to snow for the nothing selected option
     */
    noneLabel?: string;

    /**
     * The onchange method this dropdown will call
     * 
     * @param name Will be the name from props
     * @param value Will be the current value of the dropdown
     */
    onChange: (name: string, value?: string) => void;

    /**
     * The SelectInputOptions to render
     */
    options: SelectInputOption[];

    /**
     * Optional html tab index of this dropdown
     */
    tabIndex?: number;

    /**
     * Optional tooltip to display when the user hovers the (?)
     */
    tooltip?: string;

    /**
     * Current value to show in the dropdown
     */
    value?: string;
}

/**
 * Renders a dropdown
 * 
 * @param props SelectInputProps
 * @returns SelectInput
 */
const SelectInput: React.FC<SelectInputProps> = (props: SelectInputProps) => {
    /**
     * Calls the props.onChange when the user changes the value of the dropdown,
     * parent should change the props.value to update the screen
     */
    const handleChange = React.useCallback((e: React.FormEvent<HTMLSelectElement>) => {
        const val = (e.target as any).value;
        props.onChange(props.name, val);
    }, [props]);

    return <div className="mb-3">
        <div>
            {props.tooltip &&
                <i className="fa fa-question-circle me-2" title={props.tooltip} />
            }

            <label>{props.label ?? props.name}</label>

            <select
                className="form-select"
                name={props.name}
                onChange={handleChange}
                tabIndex={props.tabIndex}
                value={props.value}
            >
                <option value="">{props.noneLabel ?? '(None)'}</option>
                {props.options.map(option =>
                    <option value={option.value}>{option.label ?? option.value}</option>
                )}
            </select>

            <span className="text-danger">
                &nbsp;{props.error}
            </span>
        </div>
    </div>;
};

export default SelectInput;
