import * as React from 'react';

/**
 * Custom properties for our TextInput control
 */
interface TextInputProps {
    /**
     * Error message to display for this text box
     */
    error?: string;

    /**
     * Is this a longer text field? (Becomes a text area)
     */
    isLongText?: boolean;

    /**
     * Is this a password textbox? (if true we mask input)
     */
    isPassword?: boolean;

    /**
     * Label to display for this text box
     */
    label: string;

    /**
     * Name of the value this textbox controls (used when textbox calls onChange)
     */
    name: string;

    /**
     * The onchange method this textbox will call
     * 
     * @param name Will be the name from props
     * @param value Will be the current value of the textbox
     */
    onChange: (name: string, value?: string) => void;

    /**
     * Optional html tab index of this text box
     */
    tabIndex?: number;

    /**
     * Optional tooltip to display when the user hovers the (?)
     */
    tooltip?: string;

    /**
     * Current value to show in the textbox
     */
    value?: string;

    /**
     * Disable the textbox?
     */
    disabled?: boolean;

    /**
     * placeholder inside textbox
     */
    placeholder?: string;
}

/**
 * Renders a text box
 * 
 * @param props TextInputProps
 * @returns TextInput
 */
const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    /**
     * Calls the props.onChange when the user changes the value of the textbox,
     * parent should change the props.value to update the screen
     */
    const handleChange = React.useCallback((e: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) => {
        const val = (e.target as any).value;
        props.onChange(props.name, val);
    }, [props]);

    return <div className="mb-3">
        <div>
            {props.tooltip &&
                <i className="fa fa-question-circle me-2" title={props.tooltip} />
            }

            <label>{props.label ?? props.name}</label>

            {props.isLongText === true && <textarea
                className="form-control"
                disabled={props.disabled === true}
                name={props.name}
                onChange={handleChange}
                tabIndex={props.tabIndex}
                value={props.value} 
                placeholder={props.placeholder}
            />}

            {!props.isLongText && <input
                type={props.isPassword === true ? 'password' : 'text'}
                className="form-control"
                disabled={props.disabled === true}
                name={props.name}
                onChange={handleChange}
                tabIndex={props.tabIndex}
                value={props.value}
                placeholder={props.placeholder}
            />}

            <span className="text-danger">
                &nbsp;{props.error}
            </span>
        </div>
    </div>;
};

export default TextInput;
