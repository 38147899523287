import UserAccount from "../../Models/UserAccount";
import * as React from 'react';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import { Button } from 'reactstrap';
import * as Utils from '../../Utils';
import MuteUserForm from "./MuteUserForm";
import { Link } from "react-router-dom";
import { CurrentUserContext } from "../../contexts/AuthContext";

interface UserModerationPageState {
    user: UserAccount[];
}
const UserModerationPage: React.FC = () => {

    const [state, setState] = React.useState({user: []}as UserModerationPageState);
    const api = useApi();
    const { userAuth } = React.useContext(CurrentUserContext);

    React.useEffect(() => {
        getuser();
        
    }, [api, setState]);

    const getuser = React.useCallback(async () => {
        try {
            const users = await api.get("users");
            setState({
                ...state,
                user: users,
            });
          console.log(users);
        } catch (err: ApiException | Error | string | any) {
          console.log(err);
        }
    }, [api, setState]);

    const DemoteUser = React.useCallback(async (userId?: number) => {
        try {
            const user = await api.put(`users/demote/${userId}`)
            getuser();
        } catch (err: ApiException | Error | string | any) {
            console.log(err);
        }
        
    }, [api])

    const PromoteUser = React.useCallback(async (userId?: number) => {
        try {
            const user = await api.put(`users/promote/${userId}`)
            getuser();
        } catch (err: ApiException | Error | string | any) {
            console.log(err);
        }
        
    }, [api])

return <div>
        <table className="table table-dark table-striped ">
            <thead>
                <tr>
                    <th scope="col">UserName</th>
                    <th scope="col">Mail Address</th>
                    <th scope="col">CreatedOn</th>
                    <th scope="col">SiteRole</th>
                    <th scope="col">Change Role</th>
                    <th scope="col">IsMuted</th>
                    <th scope="col">Mute</th>
                </tr>
            </thead>
            <tbody>
                    {state.user.map((user) => (
                        <tr>
                            <td>{user.username}</td>
                            <td>{user.emailAddress}</td>
                            <td>{new Date(user.createdOn).toDateString()}</td>
                            <td>{user.siteRole}</td>
                            <td>{user.siteRole == "Admin"? <i className="fa-solid fa-crown"></i>: user.siteRole == "Moderator"? <Button onClick={() => DemoteUser(user.id)}>Demote</Button>: <Button onClick={() => PromoteUser(user.id)}>Promote</Button>}</td>
                            <td>{user.isMuted? "true": "false"}</td>
                            <td><Link to={`/users/${user.id}/muteform`} className="btn text-light bg-primary">Mute</Link></td>
                        </tr>
                    ))}
                </tbody>
        </table>
     </div>
}
export default UserModerationPage;