import * as React from 'react';
import * as Utils from '../../Utils';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import UpdateUser from '../../Commands/UpdateUser';
import useApi from '../../Utils/useApi';
import TextInput from '../common/TextInput';
import UserAccount from '../../Models/UserAccount';
import ChangeUserPassword from '../../Commands/ChangeUserPassword';
import ProfileForm from './ProfileForm';
import ChangePasswordForm from './ChangePasswordForm';
import { CurrentUserContext } from '../../contexts/AuthContext';

const UpdateProfilePage: React.FC = () => {
    const { userId } = useParams();
    const { userAuth } = React.useContext(CurrentUserContext);
    const navigate = useNavigate();
    if (!userAuth?.userAccount)
        navigate(`/users/signin?message=Please sign in to update your account&redirectUrl=${encodeURIComponent(`/users/${userId}/update`)}`);

    return <Container>
        <ProfileForm userId={Number(userId)} />

        <ChangePasswordForm userId={Number(userId)} />
    </Container>;
};

export default UpdateProfilePage;
