const getMutedDurationString = (mutedTillDate: Date) => {
    const mutedTill = new Date(mutedTillDate);
    const now = new Date();

    // Calculate the time difference in milliseconds
    const timeDiff = Math.abs(mutedTill.getTime() - now.getTime());

    // Convert the time difference to days, hours, and minutes
    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days} days, ${hours} hours, and ${minutes} minutes.`;
};

export default getMutedDurationString;