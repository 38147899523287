import * as React from 'react';
import * as Utils from '../../Utils';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container } from 'reactstrap';
import SubmitFeedback from '../../Commands/SubmitFeedback';
import TextInput from '../common/TextInput';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import { Link, useSearchParams } from 'react-router-dom';

interface ReportBugPageState {
    err?: string;
    fieldErrors: any;
    formData: SubmitFeedback;
    isSubmitted?: boolean;
};

const ReportBugPage: React.FC = () => {
    const api = useApi();
    const [qs] = useSearchParams();
    const { userAuth } = React.useContext(CurrentUserContext);

    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            fromAddress: userAuth?.userAccount?.emailAddress ?? '',
            message: '',
            pageUrl: qs.has('pageUrl') ?
                decodeURIComponent(qs.get('pageUrl') ?? '') :
                '',
            type: 'Bug',
        } as SubmitFeedback
    } as ReportBugPageState);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            await api.post('/users/feedback', formData);
            setState({ ...state, isSubmitted: true });
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    return <Container>
        {state.isSubmitted && <div>
            <h1 className="border-bottom mb-3">Thank you for helping us perfect the community experience!</h1>

            <p>
                Your bug report has been successfully submitted, and we extend our sincere gratitude for your
                invaluable contribution. As a vital member of our community, your attention to detail helps us
                maintain the highest standards and deliver an exceptional experience for all players.
            </p>

            <p>
                Our dedicated team of developers will diligently investigate the issue you reported. Your
                thoroughness in providing the steps to reproduce, expected behavior, and actual outcome greatly
                assists us in pinpointing and resolving the bug efficiently.
            </p>

            <p>
                At Mudmunity, we're committed to fostering a thriving community where players like you play an
                instrumental role in shaping our virtual realms. Together, we embark on an exciting journey of
                continuous improvement and innovation.
            </p>

            <p>
                Should you come across any other aspects you'd like to share or have questions in the future,
                don't hesitate to reach out. Your feedback and engagement mean the world to us.
            </p>

            <div className="text-end">
                <Link className="btn btn-primary" to={state.formData.pageUrl ?? '/'}>Back to Page</Link>
            </div>
        </div>}

        {!state.isSubmitted && <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Help Us Improve! Report a Bug</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        <p>
                            Your valuable feedback is essential in ensuring a smooth and immersive Mudmunity experience for everyone. If you
                            encountered a bug or unexpected behavior, we appreciate you taking the time to let us know. Please provide the
                            following details to help us investigate and address the issue:
                        </p>

                        <ol>
                            <li>
                                <strong>Steps to Reproduce:</strong><br />
                                Please outline the precise steps you took before encountering the bug. The more specific and detailed you can
                                be, the better our team can reproduce and understand the problem.
                            </li>

                            <li className="pt-2">
                                <strong>Expected Behavior:</strong><br />
                                Describe what you were expecting to happen when performing the steps above. Understanding your expectations will
                                aid us in identifying the deviation.
                            </li>

                            <li className="pt-2">
                                <strong>Actual Outcome:</strong><br />
                                Tell us what actually happened instead. Be as detailed as possible, including any error messages or visual
                                discrepancies you observed.
                            </li>
                        </ol>

                        <p>
                            Your bug report plays a significant role in our ongoing efforts to enhance Mudmunities performance and deliver an
                            exceptional community experience. Rest assured that our development team will thoroughly investigate the issue
                            you've encountered.
                        </p>

                        <p>
                            Thank you for your vigilance in helping us maintain the highest standard of quality in our community. We value your
                            support and are committed to delivering a seamless and enjoyable journey for every adventurer.
                        </p>
                    </div>

                    {!userAuth?.userAccount && <TextInput
                        error={state.fieldErrors['FromAddress']}
                        label="Email Address *"
                        name="fromAddress"
                        onChange={handleFormChange}
                        tabIndex={1}
                        tooltip="Please provide your email address so we can respond to you if necessary"
                        value={state.formData.fromAddress}
                    />}

                    <TextInput
                        error={state.fieldErrors['PageUrl']}
                        label="Page URL"
                        name="pageUrl"
                        onChange={handleFormChange}
                        tabIndex={2}
                        tooltip="Please let us know which page your feedback pertains to if applicable"
                        value={state.formData.pageUrl}
                    />

                    <TextInput
                        error={state.fieldErrors['Message']}
                        isLongText={true}
                        label="Feedback Message *"
                        name="message"
                        onChange={handleFormChange}
                        tabIndex={3}
                        tooltip="Your feedback!"
                        value={state.formData.message}
                    />

                    <div className="text-end text-danger">
                        &nbsp;
                        {state.err as string}
                    </div>
                </CardBody>

                <CardFooter className="text-end">
                    <button className="btn btn-primary" tabIndex={7}>Report Bug</button>
                </CardFooter>
            </form>
        </Card>}
    </Container>;
};

export default ReportBugPage;
