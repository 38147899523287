import * as React from 'react';
import * as Utils from '../../Utils';

import { useNavigate } from 'react-router';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import UpdateUser from '../../Commands/UpdateUser';
import UserAccount from '../../Models/UserAccount';
import useApi from '../../Utils/useApi';
import TextInput from '../common/TextInput';
import ImageInput from '../common/ImageInput';

/**
 * Custom state object we will use in this form
 */
interface UpdateUserState {
    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The UpdateUserAccount command our form is building
     */
    formData: UpdateUser;
}

export interface ProfileFormProps {
    userId: number;
}

/**
 * Profile form allows our user to update their profile fields
 *
 * @param props Props
 * @returns ProfileForm (Card)
 */
const ProfileForm: React.FC<ProfileFormProps> = (props: ProfileFormProps) => {
    const { userId } = props;
    const [state, setState] = React.useState<UpdateUserState>({
        err: '',
        fieldErrors: {},
        formData: {
            avatarImage: '',
            bio: '',
            facebookUsername: '',
            firstName: '',
            lastName: '',
            redditUsername: '',
            twitterHandle: '',
            username: ''
        } as UpdateUser
    });

    const api = useApi();
    const redirect = useNavigate();

    React.useEffect(() => {
        const getUser = async () => {
            try {
                const user: UserAccount = await api.get(`users/${userId}`);

                setState({
                    ...state,
                    formData: {
                        bio: user.bio,
                        facebookUsername: user.facebookUsername,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        redditUsername: user.redditUsername,
                        twitterHandle: user.twitterHandle,
                        username: user.username
                    }
                });
            } catch (err: ApiException | any) {
                if (err.statusCode === 404) {
                    setState({
                        ...state,
                        err: 'User not found'
                    });
                }

                console.log(err);
            }
        };

        getUser();
    }, [userId]);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            await api.put(`/users/${userId}`, formData);
            redirect(`/users/${userId}/profile`);
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    return <Card>
        <form onSubmit={handleSubmit}>
            <CardHeader>
                <CardTitle>Update Account Profile:</CardTitle>
            </CardHeader>

            <CardBody>
                <div className="mb-3">
                    Use this form to update your user account profile details.
                </div>

                <h1>About You:</h1>
                <Row>
                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['firstName']}
                            label="First Name"
                            name="firstName"
                            onChange={handleFormChange}
                            tooltip="You can share your first, or given name with the community"
                            value={state.formData.firstName}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['lastName']}
                            label="Last Name"
                            name="lastName"
                            onChange={handleFormChange}
                            tooltip="You can share your last, or family name with the community"
                            value={state.formData.lastName}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['username']}
                            label="Username"
                            name="username"
                            onChange={handleFormChange}
                            tooltip="You can select a username, or handle to go by"
                            value={state.formData.username}
                        />
                    </Col>
                </Row>

                <ImageInput
                    error={state.fieldErrors['AvatarImage']}
                    label="Avatar Image"
                    name="avatarImage"
                    onChange={handleFormChange}
                    tabIndex={2}
                    tooltip="Optionally you can provide a custom avatar"
                />

                <TextInput
                    error={state.fieldErrors['Bio']}
                    isLongText={true}
                    label="User Bio"
                    name="bio"
                    onChange={handleFormChange}
                    tooltip="Author your own bio"
                    value={state.formData.bio}
                />

                <h1 className="pt-3">Socials:</h1>
                <Row>
                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['facebookUsername']}
                            label="Facebook Username:"
                            name="facebookUsername"
                            onChange={handleFormChange}
                            tooltip="You can share your facebook username with the community"
                            value={state.formData.facebookUsername}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['redditUsername']}
                            label="Reddit Username"
                            name="redditUsername"
                            onChange={handleFormChange}
                            tooltip="You can share your reddit username with the community"
                            value={state.formData.redditUsername}
                        />
                    </Col>

                    <Col xs={12} md={4}>
                        <TextInput
                            error={state.fieldErrors['twitterHandle']}
                            label="Twitter Handle"
                            name="twitterHandle"
                            onChange={handleFormChange}
                            tooltip="You can share your twitter handle with the community"
                            value={state.formData.twitterHandle}
                        />
                    </Col>
                </Row>

                <div className="text-end text-danger">
                    &nbsp;
                    {state.err as string}
                </div>
            </CardBody>

            <CardFooter>
                <Row>
                    <Col className="text-end">
                        <button className="btn btn-primary">Update Account</button>
                    </Col>
                </Row>
            </CardFooter>
        </form>
    </Card>;
};

export default ProfileForm;
