import * as React from 'react';

export interface SortBarProps {
    sortParameters: string[];
    onChange(value: string): void;
}
const SortBar:React.FC<SortBarProps>=(props) =>{

    const handleSortChange = React.useCallback((value: string) => {
        props.onChange(value);
    },[props.sortParameters, props.onChange])
    return(
    <div className="dropdown">
        <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            Sort by
        </button>
        <ul className="dropdown-menu dropdown-menu-right">
            {props.sortParameters.map((sort) => (
            <li key={sort}>
                <a className="dropdown-item" href="#" onClick={() => handleSortChange(sort) }>{sort}</a>
            </li>
            ))}
        </ul>
    </div>)
}

export default SortBar;