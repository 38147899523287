import * as React from 'react';
import * as Utils from '../../Utils';
import { useParams } from 'react-router';
import { Button, Card, CardFooter, CardSubtitle, CardText, CardTitle, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import CreateForumComment from '../../Commands/CreateForumComment';
import Forum from '../../Models/Forum';
import useApi from '../../Utils/useApi';
import AvatarImage from '../common/AvatarImage';
import { Link } from 'react-router-dom';
import { CurrentUserContext } from '../../contexts/AuthContext';
import ForumCommentCard from './ForumCommentCard';
import ForumCommentSection from './ForumCommentSection';
import ForumHeader from './ForumHeader';

interface ForumDetailPageState {
    err?: string;
    fieldErrors: any;
    formData: CreateForumComment;
    forum?: Forum;
    isLoading?: boolean;
    totalComments: number;
}

const FormDetailPage: React.FC = () => {
    const { userAuth } = React.useContext(CurrentUserContext);
    const { forumId } = useParams();
    const api = useApi();

    const [state, setState] = React.useState<ForumDetailPageState>({
        fieldErrors: {},
        formData: {
            content: '',
            forumId: 0,
            forumcommentId: 0
        } as CreateForumComment,
        isLoading: true,
        totalComments: 0
    });

    const handleUpvote = async () => {
        try {
            await api.post(`forums/${forumId}/upvote`);
            loadForum();
        } catch (err: ApiException | Error | string | any) {
            console.log(err);
        }
    };

    const handleDownvote = async () => {
        try {
            await api.post(`forums/${forumId}/downvote`);
            loadForum();
        } catch (err: ApiException | Error | string | any) {
            console.log(err);
        }
    };

    const loadForum = React.useCallback(async () => {
        try {
            const forum: Forum = await api.get(`/forums/${forumId}`);
            const totalComments = await api.get(`forums/${forumId}/comments`);
            setState({
                ...state,
                formData: {
                    content: '',
                    forumId: 0,
                    forumcommentId: 0
                } as CreateForumComment,
                forum,
                isLoading: false,
                totalComments: totalComments
            });

        } catch (err: ApiException | any) {
            console.log(err);
        }
    }, [api, forumId]);

    React.useEffect(() => {
        loadForum();
    }, [api, forumId]);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            await api.post(`/forums/${forumId}/comment`, state.formData);
            window.location.reload();
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }

    }, [api, state]);

    const forumDetailUrl = `www.mudmunity.com/forums/${forumId}`;
    const encodedforumDetailUrl = encodeURIComponent(forumDetailUrl);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(forumDetailUrl);
      };


    const { forum } = state;

    return <div>
    {state.isLoading && <div>Loading...</div>}

    {forum && <div className="detailPage">
            <div className='navContainer d-flex justify-content-center lightblack'>
                <ForumHeader forum={forum} totalComments={state.totalComments} getForum={loadForum} className='navContain'/>
            </div>

        <div className='navContainer d-flex justify-content-center'>
            <div className="content post-content navContain">
            {forum.post}
            </div>
        </div>
        <div className='px-3'>
            <div>
                <h2 className="mb-3 mt-4 text-primary">Comments</h2>
            </div>
            <div>
                <h4>Conversation <small className="text-muted">{state.totalComments} comments</small></h4>
            </div>
            <div>
                {!userAuth?.userAccount && <div className='d-flex'>
                    <div>
                        <Link to="/users/signin" className="nav-link pe-2">Login</Link>
                    </div>|
                    <div>
                        <Link to="/users/signup" className="nav-link ps-2">SignUp</Link>
                    </div>
                </div>}
            </div>
            <div>
                {userAuth?.userAccount && <ForumCommentSection buttonname='Comment' />}

                {forum.comments?.map((comment) => <ForumCommentCard depth={0} replyTo={forum.createdBy?.username ?? forum.createdBy?.emailAddress} key={comment.id} comment={comment} />)}
            </div>
        </div>
    </div>}
</div>
};

export default FormDetailPage;
