import * as React from 'react';

export interface AvatarImageProps {
    className?: string;
    userId: number;
};

const AvatarImage: React.FC<AvatarImageProps> = (props: AvatarImageProps) => {
    const src = `/images/user/${props.userId}/avatar`;

    return <img
        className={props.className}
        src={src}
    />;
};

export default AvatarImage;
