import * as React from 'react';
import * as Utils from '../../Utils';

import { Card, CardBody, CardFooter, CardHeader, CardTitle, Container } from 'reactstrap';
import SubmitFeedback from '../../Commands/SubmitFeedback';
import TextInput from '../common/TextInput';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';
import { CurrentUserContext } from '../../contexts/AuthContext';
import { Link, useSearchParams } from 'react-router-dom';

interface SubmitFeedbackPageState {
    err?: string;
    fieldErrors: any;
    formData: SubmitFeedback;
    isSubmitted?: boolean;
};

const SubmitFeedbackPage: React.FC = () => {
    const api = useApi();
    const [qs] = useSearchParams();
    const { userAuth } = React.useContext(CurrentUserContext);

    const [state, setState] = React.useState({
        fieldErrors: {},
        formData: {
            fromAddress: userAuth?.userAccount?.emailAddress ?? '',
            message: '',
            pageUrl: qs.has('pageUrl') ?
                decodeURIComponent(qs.get('pageUrl') ?? '') :
                '',
            type: 'Feedback',
        } as SubmitFeedback
    } as SubmitFeedbackPageState);

    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            await api.post('/users/feedback', formData);
            setState({ ...state, isSubmitted: true });
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    return <Container>
        {state.isSubmitted && <div>
            <h1 className="border-bottom mb-3">Thank You for Making a Difference!</h1>

            <p>
                Your feedback has reached us loud and clear, and we couldn't be more grateful. We truly
                value the time and effort you've dedicated to sharing your thoughts with us. Your voice is
                instrumental in shaping the future of Mudmunity and making it an even more extraordinary experience.
            </p>

            <p>
                Our team is excited to delve into your feedback and explore the possibilities it holds. Your ideas,
                suggestions, and enthusiasm inspire us to push the boundaries and bring new adventures to life.
            </p>

            <p>
                As we embark on this journey together, know that every opinion matters. With your support, we'll
                continue crafting a world where each player's unique perspective contributes to the magic of MUD Games.
            </p>

            <p>
                Once again, thank you for being an essential part of our vibrant community. We look forward to evolving alongside you!
            </p>

            <div className="text-end">
                <Link className="btn btn-primary" to={state.formData.pageUrl ?? '/'}>Back to Page</Link>
            </div>
        </div>}

        {!state.isSubmitted && <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Your Voice Matters: Share Your Thoughts with Us!</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        <p>
                            We value your insights and experiences as an integral part of Mudmunity. Your feedback helps us improve and
                            shape the future of our virtual realms. Whether you have suggestions, ideas for new features, or simply want
                            to share your adventures, we're all ears!
                        </p>

                        <p>
                            Feel free to jot down your thoughts in the form below. Your feedback will be carefully reviewed by our development
                            team, and your input will play a vital role in making the Mudmunity experience even more captivating.
                        </p>

                        <p>
                            Thank you for being an essential part of our journey. We look forward to hearing from you!
                        </p>
                    </div>

                    {!userAuth?.userAccount && <TextInput
                        error={state.fieldErrors['FromAddress']}
                        label="Email Address *"
                        name="fromAddress"
                        onChange={handleFormChange}
                        tabIndex={1}
                        tooltip="Please provide your email address so we can respond to you if necessary"
                        value={state.formData.fromAddress}
                    />}

                    <TextInput
                        error={state.fieldErrors['PageUrl']}
                        label="Page URL"
                        name="pageUrl"
                        onChange={handleFormChange}
                        tabIndex={2}
                        tooltip="Please let us know which page your feedback pertains to if applicable"
                        value={state.formData.pageUrl}
                    />

                    <TextInput
                        error={state.fieldErrors['Message']}
                        isLongText={true}
                        label="Feedback Message *"
                        name="message"
                        onChange={handleFormChange}
                        tabIndex={3}
                        tooltip="Your feedback!"
                        value={state.formData.message}
                    />

                    <div className="text-end text-danger">
                        &nbsp;
                        {state.err as string}
                    </div>
                </CardBody>

                <CardFooter className="text-end">
                    <button className="btn btn-primary" tabIndex={7}>Send Feedback</button>
                </CardFooter>
            </form>
        </Card>}
    </Container>;
};

export default SubmitFeedbackPage;
