import * as React from 'react';
import TextInput from '../common/TextInput';
import { MuteUserAccount } from '../../Commands/MuteUserAccount';
import { ApiException } from '../../Api';
import useApi from '../../Utils/useApi';
import { Card, CardHeader, CardTitle, CardBody, Row, Col, CardFooter } from 'reactstrap';
import * as Utils from '../../Utils';
import SelectInput, { SelectInputOption } from '../common/SelectInput';
import { useNavigate, useParams } from 'react-router';

interface MuteUserFormState {
    formData: MuteUserAccount;
    durations: string [];
}
const MuteUserForm: React.FC = () => {
    const api = useApi();
    const navigate = useNavigate();
    const {userId} = useParams();

    const [state, setState] = React.useState({formData: {}as MuteUserAccount, durations: ["1", "2", "5", "7", "10"] }as MuteUserFormState);

    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData } = state;

        try {
            await api.put(`/users/mute/${userId}`, formData);
            navigate('/users/moderation');
        } catch (err: ApiException | any) {
            console.log(err);
        }
    }, [api, state]);

    const handleFilterChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFilterChange(state, setState, name, value);
    }, [state]);


return <Card>
     <form onSubmit={handleSubmit}>
         <CardHeader>
             <CardTitle>Mute User Accounts:</CardTitle>
         </CardHeader>

         <CardBody>
             <div className="mb-3">
                 Use this form to mute users.
             </div>
             <Row>
                <Col xs={12} md={4}>
                    <TextInput
                        error={undefined}
                        label="Reason For Mute"
                        name="muteReason"
                        onChange={handleFilterChange}
                        value={state.formData.muteReason}
                        tabIndex={1}
                    />
                </Col>
                <Col xs={12} md={4}>
                    <SelectInput
                        error={undefined}
                        label="Mute Duration (In Days)"
                        name="muteDuration"
                        onChange={handleFilterChange}
                        options={state.durations.map(v => ({ value: v } as SelectInputOption))}
                        tabIndex={2}
                        value={state.formData.muteDuration}
                    />
                </Col>
             </Row>
         </CardBody>
         <CardFooter>
             <Row>
                 <Col className="text-end">
                     <button className="btn btn-primary">Submit</button>
                 </Col>
             </Row>
         </CardFooter>
     </form>
 </Card>;
}
export default MuteUserForm;