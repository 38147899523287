import * as React from 'react';
import { useNavigate, useParams } from 'react-router';
import useApi from '../../Utils/useApi';
import CreateGamePage from './CreateGamePage';
import Game from '../../Models/Game';
import { ApiException } from '../../Api';
import GameForm from './GameForm';
import { CurrentUserContext } from '../../contexts/AuthContext';

/**
 * Update Game page, will render the GameForm in update mode for the
 * selected game (from route id param)
 * 
 * @returns UpdateGamePage
 */
const UpdateGamePage: React.FC = () => {
    const { id } = useParams();
    const { userAuth } = React.useContext(CurrentUserContext);
    if (!userAuth?.userAccount)
        window.location.href = `/users/signin?message=Please sign in to update games in the catalog&redirectUrl=${encodeURIComponent(`/games/${id}/update`)}`;

    const [game, setGame] = React.useState<Game>();
    const api = useApi();

    React.useEffect(() => {
        const getGame = async () => {
            try {
                const gameRes = await api.get(`games/${id}`);
                setGame(gameRes);
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        };

        getGame();
    }, [api, id, setGame]);

    return <div className="w-50 m-auto pt-5">
        {game && <GameForm game={game} />}
    </div>;
};

export default UpdateGamePage;