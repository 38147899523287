import * as React from 'react';
import { Col } from 'reactstrap';

export interface StarRatingProps {
    rating: number;
}

const StarRating: React.FC<StarRatingProps> = (props) => {
    const { rating } = props;
  
    return<>
        {Array.from({ length: rating }).map(() => (
          <Col className='col-auto px-0'>
            <i className="fa-solid fa-star fa-xl text-danger"></i>
          </Col>))
        }
    </>
};
  
export default StarRating;