import * as React from 'react';
import * as Utils from '../../Utils';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, CardTitle, Col, Container, Row } from 'reactstrap';
import { ApiException } from '../../Api';
import CreateUser from '../../Commands/CreateUser';
import useApi from '../../Utils/useApi';
import TextInput from '../common/TextInput';
import ImageInput from '../common/ImageInput';

/**
 * Custom state object we will use in this form
 */
interface CreateUserState {
    /**
     * The general error message to show by the create button
     */
    err?: string | Error;

    /**
     * The key value pair object of individual field errors
     */
    fieldErrors: any; // Actually a dictionary array

    /**
     * The CreateUserAccount command our form is building
     */
    formData: CreateUser;
}


const CreateUserPage: React.FC = () => {
    /**
     * Initialize our state object
     */
    const [state, setState] = React.useState({
        err: '',
        fieldErrors: {},
        formData: {
            avatarImage: '',
            confirmPassword: '',
            emailAddress: '',
            password: ''
        } as CreateUser
    } as CreateUserState);

    const api = useApi();
    const navigate = useNavigate();

    /**
     * Called when one of our form inputs change, this updates our state.formData,
     * clears state.err and clears state.fieldErrors[name]
     */
    const handleFormChange = React.useCallback((name: string, value?: string) => {
        Utils.handleFormChange(state, setState, name, value);
    }, [state]);

    /**
     * Called when the user clicks the create button. Clears all errors, validates
     * the CreateUser command and if so POSTs to /users. Displays errors if create
     * fails, redirects to /users/signin if success
     */
    const handleSubmit = React.useCallback(async (e: React.SyntheticEvent) => {
        e.preventDefault();
        const { formData, fieldErrors } = state;

        setState({
            ...state,
            err: '',
            fieldErrors: {}
        });

        try {
            if (formData.password !== formData.confirmPassword) {
                fieldErrors['confirmPassword'] = 'Passwords do not match';

                throw {
                    fieldErrors,
                    message: 'Failed to create user account',
                    statusCode: 500
                } as ApiException;
            }

            const newUser = await api.post('/users', formData);
            navigate(`/users/verifyEmail?emailAddress=${encodeURIComponent(newUser.emailAddress)}`);
        } catch (err: ApiException | any) {
            console.log(err);

            setState({
                ...state,
                err: err.message,
                fieldErrors: err.fieldErrors,
                formData,
            });
        }
    }, [api, state]);

    return <Container>
        <Card>
            <form onSubmit={handleSubmit}>
                <CardHeader>
                    <CardTitle>Create Account:</CardTitle>
                </CardHeader>

                <CardBody>
                    <div className="mb-3 pb-2 border-bottom">
                        Join our thriving community of MUD gamers and embark on unforgettable adventures in immersive text-based worlds.
                        Sign up now to connect with fellow MUD enthusiasts, discover captivating games, and forge lasting friendships in
                        our vibrant community!
                    </div>

                    <TextInput
                        error={state.fieldErrors['emailAddress']}
                        label="Email Address *"
                        name="emailAddress"
                        onChange={handleFormChange}
                        tabIndex={1}
                        tooltip="We require accounts to be registered to a valid email address"
                        value={state.formData.emailAddress}
                    />

                    <ImageInput
                        error={state.fieldErrors['AvatarImage']}
                        label="Avatar Image"
                        name="avatarImage"
                        onChange={handleFormChange}
                        tabIndex={2}
                        tooltip="Optionally you can provide a custom avatar"
                    />

                    <TextInput
                        error={state.fieldErrors['password']}
                        isPassword={true}
                        label="Password *"
                        name="password"
                        onChange={handleFormChange}
                        tabIndex={3}
                        tooltip="Please provide a strong password"
                        value={state.formData.password}
                    />

                    <TextInput
                        error={state.fieldErrors['confirmPassword']}
                        isPassword={true}
                        label="Confirm password *"
                        name="confirmPassword"
                        onChange={handleFormChange}
                        tabIndex={4}
                        tooltip="Confirm that this is the password you want to use"
                        value={state.formData.confirmPassword}
                    />

                    <div className="text-end text-danger">
                        &nbsp;
                        {state.err as string}
                    </div>
                </CardBody>

                <CardFooter>
                    <Row>
                        <Col xs={6}>
                            <Link to="/users/signin">Already have an account? Sign in here</Link>
                        </Col>

                        <Col className="text-end">
                            <button className="btn btn-primary" tabIndex={7}>Create Account</button>
                        </Col>
                    </Row>
                </CardFooter>
            </form>
        </Card>
    </Container>;
};

export default CreateUserPage;
