import * as React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import Game from '../../Models/Game';
import StarRating from '../common/StarRating';
import CoverImage from '../common/CoverImage';
import useApi from '../../Utils/useApi';
import { ApiException } from '../../Api';

export interface GameCardProps {
    game: Game
    onGenreSelected?: (name:string, value?: string) => void;
    onStatusSelected?: (name:string, value?: string) => void;
    onPopulationSelected?: (name:string, value?: string) => void;
};

interface GameCardState {
    totalComments: number;
}

const GameCard: React.FC<GameCardProps> = (props: GameCardProps) => {
    const api = useApi();
    const { game } = props;

    const handleGenreSelected = React.useCallback((name: string, value: string) => {
        if (props.onGenreSelected){
          props.onGenreSelected(name , value);
        }
      }, [props]);

      const websiteUrl = game.websiteUrl;
      const hasHttpProtocol = websiteUrl && (websiteUrl.startsWith('http://') || websiteUrl.startsWith('https://'));
      const fixedWebsiteUrl = hasHttpProtocol ? websiteUrl : websiteUrl ? `http://${websiteUrl}` : '';

    const handleStatusSelected = React.useCallback((name: string, value: string) => {
    if (props.onStatusSelected){
        props.onStatusSelected(name , value);
    }
    }, [props]);

    const handlePopulationSelected = React.useCallback((name: string, value: string) => {
        if (props.onPopulationSelected){
          props.onPopulationSelected(name , value);
        }
      }, [props]);


    const [state, setState] = React.useState({totalComments: 0}as GameCardState);

    const gameDetailUrl = `www.mudmunity.com/games/${game.id}`;
    const encodedGameDetailUrl = encodeURIComponent(gameDetailUrl);

    const handleCopyLink = () => {
        navigator.clipboard.writeText(gameDetailUrl);
      };

    function trimString(text:string) {
        const maxLength = 500;
        const maxNewLines = 3;
        const ellipsis = "...";
      
        let trimmedText = text.slice(0, maxLength);
      
        let thirdNewLineIndex = -1;
        let newLineCount = 0;
        for (let i = 0; i < trimmedText.length; i++) {
          if (trimmedText[i] === '\n') {
            newLineCount++;
            if (newLineCount === maxNewLines) {
              thirdNewLineIndex = i;
              break;
            }
          }
        }
      
        if (thirdNewLineIndex !== -1) {
          trimmedText = trimmedText.slice(0, thirdNewLineIndex);
        }
      
        if (trimmedText.length < text.length) {
          trimmedText += ellipsis;
        }
      
        return trimmedText;
      }  

      React.useEffect(() => {
        getComment();
     }, [api, game.id]);


        const getComment = React.useCallback(async () => {
            try {
                const totalComments:number = await api.get(`games/${game.id}/comments`);
                setState({
                    totalComments: totalComments
                });
            } catch (err: ApiException | Error | string | any) {
                console.log(err);
            }
        }, [api, game.id]);

          

    return <div>
        <Card className='my-3 indexCard'>
            <CardBody>
                <div className="text-lead lh-1 align-self-start py-0 indexCardBody">
                    <CoverImage gameId={game.id} />
                    <div className='titletext px-3'>
                        <CardTitle className='me-1 text-primary'>      
                                <Row>
                                    <Col className='col-auto'>
                                    <Link className='text-decoration-none text-primary' to={`/games/${game.id}`}>
                                        <h1 className='mt-2'>{game.name}</h1></Link>
                                    </Col>
                                    <Col className='col-auto'>
                                        {game?.genre && (<button className='btn btn-outline-primary py-1 px-2 mb-1' onClick={() => props.onGenreSelected?.('Genre', game.genre)}>{game.genre}</button>)
                                       }
                                    </Col>
                                    <Col className='col-auto'>
                                        {game?.status && (<button className='btn btn-outline-warning py-1 px-2 mb-1' onClick={() => props.onStatusSelected?.('StatusType', game.status)}>{game.status}</button>)
                                       }
                                    </Col>
                                    <Col className='col-auto'>
                                        {game?.population && (<button className='btn btn-outline-success py-1 px-2 mb-1' onClick={() => props.onPopulationSelected?.('Population', game.population)}>{game.population}</button>)
                                       }
                                    </Col>
                                </Row>
                            
                            <p className="small text-muted">
                                <>
                                    Started by &nbsp;
                                    <Link to={`/users/${game.createdById}/profile`} className='brightgreen greenonhover text-decoration-none'>{game.createdBy ? game.createdBy.username ?? game.createdBy.emailAddress : 'System'}</Link>
                                    - {new Date(game.createdOn).toDateString()}
                                    <a href={fixedWebsiteUrl} target="_blank" className='ms-2'>{fixedWebsiteUrl}</a>
                                </>
                            </p>
                        </CardTitle>

                        <CardText className='m-0 p-0 post-content'>
                            {trimString(game.description)}
                        </CardText>
                    </div>
                </div>    
            </CardBody>
            <CardFooter>
                <Row className='mb-2 pt-2'>
                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-chevron-up fa-lg me-1 ms-3"></i>{game.upvotes}
                    </Col>

                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-chevron-down fa-lg me-1"></i>{game.downvotes}
                    </Col>

                    <Col className='col-auto pt-1'>
                        <i className="fa-solid fa-comment me-1 ms-3"></i> {state.totalComments} Comments
                    </Col>

                    <Col className='col-auto'>
                    <div className="btn-group dropend">
                        <button type='button' className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-share me-1"></i>
                        Share
                        </button>
                        <ul className="dropdown-menu dropdown-menu-right">
                            <li><a href={`https://www.facebook.com/sharer/sharer.php?u=${encodedGameDetailUrl}&quote=ComeJoinUs`} target="_blank" className='dropdown-item'><i className="fa-brands fa-facebook pe-2"></i>Facebook</a></li>
                            <li><a href={`https://twitter.com/intent/tweet?url=${encodedGameDetailUrl}&text=ComeJoinUs"`} className="dropdown-item"  target="_blank"><i className="fa-brands fa-twitter pe-2"></i>Twitter</a></li>
                            <li><a href={`https://www.reddit.com/submit?url=${encodedGameDetailUrl}&title=ComeJoinUs"`} className="dropdown-item"  target="_blank"><i className="fa-brands fa-reddit pe-2"></i>Reddit</a></li>
                            <li> <hr className="dropdown-divider"></hr></li>
                            <li><a className="dropdown-item hand-cursor" onClick={handleCopyLink}><i className="fa-solid fa-copy pe-2"></i>Copy Link</a></li>
                        </ul>
                    </div>
                    </Col>
                </Row>
            </CardFooter>
        </Card>
    </div>;
};

export default GameCard;